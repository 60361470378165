@import 'assets/styles/mixins';

.root {
  @include flexCenter;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 50%;
  max-width: 70em;
  padding: 2.4rem;
  margin: auto;

  .subtitle {
    width: 100%;
    margin: 1.2rem 0;
    white-space: pre-line;
  }

  @include viewportMobile {
    max-width: 100%;
    justify-content: flex-start;
    padding: 2.4rem;

    .button {
      width: 100%;
      margin-top: 1.2rem;
    }
  }
}

.heading {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 15rem;
  margin-top: 10rem;

  .title {
    flex: 2;
    display: flex;
    align-items: center;
    margin: 1.2rem 1.2em 1.2rem 0;
    min-width: 40%;
    max-width: 100%;
    font-size: calc(1rem + 1.5vw);
    white-space: pre-line;
  }

  .logo {
    flex: 1;
    min-width: 30%;
    max-width: 50%;
    object-fit: contain;
  }

  @include viewportMobile {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    margin-top: 5rem;
    height: auto;

    .title {
      margin-right: 0;
      font-size: 2.5rem;
    }

    .logo {
      margin-bottom: 1.2rem;
      box-sizing: border-box;
      margin-left: 25%;
    }
  }
}

.mediaWrapper {
  box-shadow: 0px 20px 50px rgb(0 0 0 / 15%);

  .image {
    overflow: hidden;
    border-radius: 6px;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;

    img {
      width: 100%;
      position: absolute;
    }

    .video {
      margin: 1.2rem 0;
      width: 100%;
      min-width: 43rem;
    }
  }

  @include viewportMobile {
    margin: 1.2rem 0;
  }
}
