.root {
  &:last-of-type {
    margin-bottom: 0;
  }
}

.header {
  grid-column: 1 / span 11;
  width: 100%;
  display: flex;
  align-items: center;
}