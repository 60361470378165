@import 'assets/styles/mixins';

.content {
  padding: 6rem;

  .text{
    margin-top: 1rem;
    font-size: 15px;
    font-weight: 400;
  }
}
.input{
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
    input{
        border-top: $white !important;
        border-left: $white !important;
        border-right: $white !important;
        margin-right: 2rem;
        border-radius: 0rem !important;
      }
}

.error {
  position: relative;
  bottom: 0;
  left: 0;
  color: $error-color;
  font-size: 1.3rem;
  text-transform: capitalize;

  &::before {
    content: '* ';
  }
}