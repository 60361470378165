@import 'assets/styles/mixins';

.root {
  .menu {
    top: 120%;
    position: absolute;
    z-index: 99;
    margin: 0;
    left: 0;
    list-style-type: none;
    padding-inline-start: 0;
    min-width: initial;
    overflow: hidden;
    height: initial;
    max-height: 0;
    border-width: 0;
    transition: all .2s ease-in-out;
  
    .item {
      @include text-body;
      padding: .5em 1em;
      cursor: pointer;
      white-space: nowrap;
      transition: all .4s ease-in-out;
      display: flex;
      align-items: center;
  
      &:hover {
        color: $white;
        background-color: $brand-color-light;
      }
    }
  }

  &Opened {
    .menu {
      padding: 0.2em 0;
      max-height: 2em;
      border-width: 1px;
    }
  }
}

