@import 'assets/styles/mixins';
@import 'assets/styles/colors';

.root {
  background-color: $white;
  width: 100%;
  padding: .95rem 0;
  border: 1px solid $base-grey-10;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 0.3fr 0.7fr 1.5fr 1.5fr repeat(3, 1fr);
  align-items: center;
  font-size: 1.4rem;
  margin-bottom: 16px;
  min-width: 100rem;
  overflow: auto;

  &WithoutCompany {
    grid-template-columns: 0.3fr 1.5fr 1.5fr repeat(3, 1fr);
  }
  h1{
    padding: 0px 10px;
  }
}

.deleteBtn {
  @include pseudo-states() {
    background-color: $red-dark; // placeholder only
  }
  margin-left:8px;
}

.verifyStatus {
  width: fit-content;
  text-transform: capitalize;
  margin: 0px 10px;
}


.actions {
  width: 100%;
  justify-self: flex-end;
  padding: 0 1em;
}

.checkbox {
  width: 2rem;
  margin-left: .8rem;
}

.name {
  padding-left:10px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: $branding-gradient-with-opacity;
}

::-webkit-scrollbar-thumb:hover {
  background: $branding-gradient;
}
