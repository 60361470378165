@import 'assets/styles/mixins';

@mixin content {
  height: 600px;
  width: 100%;
}

.urlBtn {
  width: 100%;
  border-radius: 10px;
  margin-top: 2rem;
  a {
    color: white;
    text-decoration: none;
  }
}
iframe {
  @include content;
}
