@import 'assets/styles/mixins';

.root {
  @include flexCenter;
  @include text-body;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  color: $white;
  transition: .4s all;
  height: 0;
  opacity: 0;

  &Visible {
    height: 3rem;
    opacity: 1;
  }

  &Success { background-color: $success-color; }
  &Error { background-color: $error-color; }
  &Info { background-color: $info-color; }
}
