@import 'assets/styles/colors';
@import 'assets/styles/mixins';

.root {
  @include fullScreen;
  @include flexCenter;
  flex-direction: column;

  :global(.card) {
    overflow: hidden;
  }

  .content {
    padding: 6rem;
  }

  .button{
    border: none;
    color: #4932d0;
    background: none;
    cursor: pointer;
    font-size: 12px;
    padding: 0rem;
  }
  .plain{
    cursor: not-allowed !important;
  }
  .timer{
    display: contents;
    font-size: 11px;
  }
}

.card {
  padding: 5rem 0;
  margin-top: 3rem;

  .title {
    position: relative;
    margin-bottom: 2rem;
  
    &::before {
      left: 56% !important;
      top: 60% !important;
      background-size: 47%;
    }
  }
  
  .img {
    margin-bottom: 2rem;
  }

  .subtitle {
    width: 75%;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .smallText {
    color: $base-grey-50;
  }
}
