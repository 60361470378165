.root {
    width: 50%;
    margin: auto;
    
    .header {
      .iconButton {
        position: absolute;
        right: 2.5rem;
        width: 4rem;
        height: 4rem;
      }
    }
    .subHeading{
        margin-top: 2rem;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }
    
  }