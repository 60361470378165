@import 'assets/styles/mixins';

.root {
  @include flexCenter;
  flex-direction: column;
  align-items: flex-start;
  min-width: 50%;
  max-width: 70em;
  margin: auto;

  @include viewportMobile {
    margin: 0;
    width: 100%;
    padding: 2.4rem;
    .subtitle {
      margin: 1.2rem 0 1.2rem 0;
    }
  }
}

.heading {
  display: flex;
  justify-content: space-between;
  margin-top: 10rem;
  margin-bottom: 3em;
  width: 100%;

  .title {
    flex: 2;
    display: flex;
    align-items: center;
    margin: 1.2rem 1.2em 1.2rem 0;
    min-width: 40%;
    max-width: 100%;
    font-size: calc(1rem + 2vw);
  }

  .logo {
    flex: 1;
    min-width: 30%;
    max-width: 50%;
    object-fit: contain;
  }

  @include viewportMobile {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: 0;

    .logo {
      margin-bottom: 1.2rem;
      box-sizing: border-box;
      margin-left: 25%;
    }

    .title {
      width: 100%;
      margin: 1.2rem 0 1.2rem 0;
      font-size: 2.5rem;
    }
  }
}

.blocks {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(22em, 1fr));
  row-gap: 2em;
  column-gap: 2em;
  place-items: center;
  margin-bottom: 32px;


  @include viewportMobile {
    grid-template-columns: 100%;
  }
}
