@import 'assets/styles/variables';

@mixin floatingIcon {
  height: 1.6rem;
  width: 1.6rem;
  padding: 0 .7rem;
}

.root {
  padding: .7rem;
  border-radius: 6px;
  font-size: 1.3rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin: $margin-default 0;

  &Error {
    color: $error-color;
    border: 1px solid $error-color;
    background-color: $error-color-light;
  }

  &Success {
    color: $success-color;
    border: 1px solid $success-color;
    background-color: $success-color-light;
  }

  &Info {
    color: $info-color;
    border: 1px solid $info-color;
    background-color: $info-color-light;
  }

  .message {
    margin: 0;
    line-height: 1.1rem;
  }

  .statusIcon { 
    @include floatingIcon;
  }

  .closeIcon {
    @include floatingIcon;
    cursor: pointer;
  }
}