@import 'assets/styles/mixins';

.root {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: .5rem 0;

  label { @include text-body; }

  .slider {
    position: relative;
    width: 30px;
    height: 18px;

    input {
      position: absolute;
      z-index: 2;
      zoom: 2;
      top: -2px;
      left: 1px;
      margin: 0;
      cursor: pointer;
      opacity: 0;
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $base-grey-25;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 33px;
  
      &:before {
        position: absolute;
        content: "";
        height: 14px;
        width: 14px;
        left: 2px;
        top: 2px;
        border-radius: 50%;
        background-color: $white;
        -webkit-transition: .4s;
        transition: .4s;
      }
    }

    input:checked + span {
      background-color: $brand-color;

      &::before { transform: translateX(12px); }
    }
  }
}
