@import 'assets/styles/variables';



.tabsContainer {
    display: flex;
    flex-wrap: wrap;

}

.tabList {
    margin-right: 100px;
    min-width: 300px;
    width: 15%;
}

.tabItem {
    cursor: pointer;
    border: 1px solid rgb(229, 223, 242);
    align-items: center;
    color: $base-grey;
    display: flex;
    font-weight: 600;
    justify-content: space-between;
    margin-bottom: 15px;
    position: relative;
    width: 100%;
    background: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 8px;
    padding: 1em 2em;
    transition: all 0.4s ease 0s;
    font-size: 14px;

    &Active{
        background: $branding-gradient;
        font-weight: bold;
        color: white;
    }
}


.tabItem:hover {
  background: $branding-gradient;
  color: white;
}

.tabContent {
  flex-grow: 1;
}

.tabPane {
  display: none;
}

.tabPaneActive {
  display: block;
}
