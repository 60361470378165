@import 'assets/styles/mixins';

.root {
  @include fullScreen;
  @include flexCenter;
  flex-direction: column;

  :global(.card) {
    overflow: hidden;
  }

  .content {
    padding: 5rem;
  }
}

.footer {
  color: $base-grey-50;
  background-color: $base-grey-5;
  padding: 0 !important;
  font-size: 1.1rem;
}