@import 'assets/styles/mixins';

.root {

  & > input { display: none; }

  :global(.react-datepicker-wrapper) {
    width: 100%;
  }

  :global(.react-datepicker__input-container) { 
    input {
      @include input;
    }
  }
  
  :global(.react-datepicker) {
    font-size: 12px;
  }

  :global(.react-datepicker__header) {
    :global(.react-datepicker__current-month) {
      font-size: 13px;
      line-height: 21px;
    }
  }

  :global(.react-datepicker__day-name) {
    font-size: 11.2px;
    width: 23.8px;
    line-height: 23.8px;
    margin: 2.3px;
  }



  :global(.react-datepicker__day-name), 
  :global(.react-datepicker__day) {
    width: 23.8px;
    line-height: 23.8px;
    margin: 2.3px;
  }
  :global(.react-datepicker__day--selected) {
    background-color: $brand-color;
  }
}

