@import 'assets/styles/mixins';

.root {
  @include flexCenter;

  display: inline-flex;
  width: 3.5rem;
  height: 3.5rem;
  color: $base-grey-50;
  background-color: $base-grey-5;
  border: 1px solid $base-grey-10;
  border-radius: 50%;
  cursor: pointer;
  transition: all .4s ease-in-out;

  &:hover {
    background-color: $brand-color-dark;
    color: $white;
  }
}
