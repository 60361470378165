.root {
  margin-top: 5.2rem;
  .canvas {
    transform: none;
    span {
      display: none;
    }
    canvas {
      width: 100% !important;
      height: 30% !important;
      scale: 1.1;
    }
  }
  .pageBar {
    margin-top: 7.5rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}
