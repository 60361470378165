@import 'assets/styles/mixins';

@mixin previewLabel {
  @include text-body;
  position: absolute;
  bottom: 0;
}

.root {
  position: relative;  
  padding-bottom: 3rem;

  .starsWrapper {
    display: flex;
    justify-content: space-between;
  }

  .star {
    cursor: pointer;
    transition: all .4s ease-in-out;
  }

  .labelStart {
    @include previewLabel;
    left: 0;
  }

  .labelEnd {
    @include previewLabel;
    right: 0;
  }
}

.disabled {
  &:hover {
    cursor: not-allowed;
  }
}