@import 'assets/styles/mixins';

.root {
  @include button;

  .icon {
    padding-right: .5rem;
    width: 1.5rem;
    height: 1.5rem;
  }

  &Small {
    height: 3rem;
    padding: 0 1.5rem;
  }

  &:disabled {
    background-color: $brand-color-light;
    cursor: not-allowed;

    @include pseudo-states() {
      background-color: $brand-color-light;
    }
  }

  &--primary {
    background-color: $brand-color;
    color: $white;

    @include pseudo-states() {
      background-color: $brand-color-dark;
    }
  }

  &--secondary {
    background-color: $base-grey-5;
    color: $base-grey-50;
    border: 1px solid $base-grey-10;

    @include pseudo-states() {
      background-color: $brand-color-dark;
      color: $white;
    }

    &:disabled {
      background-color: $base-grey-10;
      color: $base-grey-25;
      cursor: not-allowed;
  
      @include pseudo-states() {
        color: $base-grey-25;
        background-color: $base-grey-10;
      }
    }
  }

  &--secondary-reverse {
    background-color: $white;
    color: $base-grey-50;
    border: 1px solid $base-grey-10;

    @include pseudo-states() {
      background-color: $brand-color-dark;
      color: $white;
    }

    &:disabled {
      color: $base-grey-25;
      background-color: $base-grey-10;

      @include pseudo-states() {
        color: $base-grey-25;
        background-color: $base-grey-10;
      }
    }
  }

  &--delete {
    background-color: $red;

    @include pseudo-states() {
      background-color: $red-dark; // placeholder only
    }
  }

  &--link {
    background-color: transparent;
    border: none;
    color: $brand-color;
    padding: 0;
    font-weight: 500;

    @include pseudo-states() {
      color: $brand-color-dark;
      background-color: transparent;
    }
  }
}
