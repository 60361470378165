@import 'assets/styles/mixins';

.header {
  padding: 3rem;
  background-color: $white;

  &WithDivider {
    border-bottom: 1px solid $base-grey-10;
  }

  @include viewportMobile {
    .searchInput{
      width: 100%;
    }
  }

}

.tableHeaders {
  width: 100%;
  display: grid;
  grid-template-columns: .1fr .7fr repeat(4, 1fr);
  align-items: center;
  font-size: 1.2rem;
  background-color: $white;
  padding: .5rem 3rem;
  border-bottom: 1px solid $base-grey-10;

  span {
    @include text-body;
  }

  @include viewportMobile {
    min-width: 80rem;
  }
}

.tableRows {
  padding: 3rem;
  @include viewportMobile {
    min-width: 80rem;
  }
}

.select {
  width: 16rem;
}

.filter {
  cursor: pointer;
}

.statusParent {
  grid-column-start: 2;
}

.message {
  height: 60vh;
}

.addBtn {
  width: 13rem;
  height: 4.5rem;
}

.centerBtn {
  height: 4.5rem;
}

.centerTitle {
  font-size: 2rem;
}
