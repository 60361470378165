@import "assets/styles/mixins";
@import "assets/styles/colors";

.root {
  background-color: $white;
  width: 100%;
  padding: 0.95rem 0;
  border: 1px solid $base-grey-10;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 0.1fr 0.7fr repeat(4, 1fr);
  align-items: center;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
}

.statusParent {
  grid-column-start: 2;
  font-family: inter, sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  color: #333333;
}

.actions {
  display: flex;
  justify-content: flex-start;
  .impersonateButton{
    padding: 0 1rem !important;
    width: 13rem !important;
  }
}

.name {
  position: relative;
  span {
    position: absolute;
    left: -2.5rem;
  }
}

.email {
  position: relative;
  span {
    position: absolute;
    left: -2.5rem;
  }
}
