.row {
  display: flex;
  align-items: flex-start;

  & > * {
    width: 50%;
  } 
}

.text{
  overflow: hidden;
  text-overflow: ellipsis;
}