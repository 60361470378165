@import 'assets/styles/mixins';

@mixin previewLabel {
  @include text-body;
  position: absolute;
  bottom: -2.5rem;
}

.scalePreview {
  position: relative;

  .scaleWrapper {
    width: 100%;
    display: flex;
    border-radius: 6px;
    border: 1px solid $base-grey-10;
    overflow: hidden;

    span {
      @include flexCenter;
      flex: 1;
      height: 4rem;
      background-color: $base-grey-5;
  
      &:not(:last-of-type) {
        border-right: 1px solid $base-grey-10;
      }
    }
  }

  .labelStart {
    @include previewLabel;
    left: 0;
  }

  .labelEnd {
    @include previewLabel;
    right: 0;
  }
}