@import 'assets/styles/colors';

.option {
  width: 100%;

  .icon { color: $base-grey-50; }

  .answer{
    margin-left: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .checkBox{
      width: 2.5rem;
      border-color: $brand-color;
    }
  }

  .input {
    width: 100%;
  
    input { background-color: $white !important; }
  }
}