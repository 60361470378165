@import 'assets/styles/mixins';
.title {
  align-self: flex-start;
}

.noBorder {
  border: none !important;
}

.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.submitBtn{
  width: 50%;
}

.listContainer{
  padding: 16px;
  background: $base-grey-5;
  border: 1px solid $base-grey-10;
  border-radius: 6px;
  width: 100%;
  height: 100px;
  min-height: 360px;
  overflow: auto;

  .list{
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 3.6rem;
    color: $base-grey;
    cursor: pointer;
    .name{
      margin-left: 5px;
    }
    .email{
      margin-left: 5px;
      color:$base-grey-35
    }
  }
  .list:hover{
    background: $base-grey-10;
    .checkBox{
      border-color: rgba(0, 140, 206, 0.2);
    }
  }
}
