@import 'assets/styles/variables';
@import 'assets/styles/colors';

.root {
  position: fixed;
  background-color: transparent;
  overflow: hidden;
  z-index: 9999;
  bottom: -200%;
  right: 0;
  top: 0;
  left: 0;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: visibility .3s ease-in-out, bottom .3s ease-in-out, background-color .3s ease-in-out .1s;

  &Visible {
    background-color: $overlay-color;
    visibility: visible;
    bottom: 0;
  }
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2.4rem 2.8rem;
  width: 100%;
  overflow-y: auto;

  .textDiv {
    width: 80%;
    margin-bottom: 2rem;

    .title {
      margin-bottom: 1rem;
    }
  }
}
