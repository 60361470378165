@import 'assets/styles/mixins';
.root {
  height: 100%;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.navItems{
    padding: 0px 0px 12px 0px;
    margin: 0px 32px 0px 0px;
    transition: all 0.25s ease-in-out;
}

.navItems:hover {
    color: $brand-color;
    border-bottom: 2px solid $brand-color;
}
