@import 'assets/styles/mixins';
@import 'assets/styles/colors';

.root {
  max-width: 70em;
}

.status {
  text-transform: capitalize;
}

.internelStakeholderRow {
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  grid-column-gap: 1.5em;
  margin-top: 2em;
}

.header {
  grid-column-start: span 10;
  justify-self: start;

  .title {
    display: flex;
    font-size: 30px;
  }
}

.content {
  div:last-of-type {
    margin-bottom: 0;
  }

  .blockResult {
    h4 {
      @include text-small;
      margin-bottom: 0;
    }
  }
}

.content {
  border-bottom: 1px solid $base-grey-10;

  div:last-of-type {
    margin-bottom: 0;
  }
}

.resend {
  svg {
    margin: .2rem .2rem 0 0;
  }
}

.iconsDiv {
  display: flex;
}

.row {
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}