@import 'assets/styles/mixins';

.root {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  margin: auto;

  .block {
    flex: 1;
    margin-top: 10rem;
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;

    @include viewportMobile {
      margin-bottom: 0rem !important;
      margin-top: 0rem !important;
    }
  }

  @include viewportMobile {
    height: 100%;
    margin: 0 !important;

    .block {
      margin-top: 0;
    }
  }
}
