@import 'assets/styles/mixins';

.header{
  width: 100%;
  padding: 28px;
  border-bottom: 1px solid $base-grey-10;
  .title {
    align-self: flex-start;
  }
  p{
    padding: 0 10px;
    color: $base-grey-50;
    font-size: 14px;
    margin: 4px 0px 0px 0px;
  }
}
.modalHeader{
  padding: 0;
  overflow: visible;
}

.root{
  background: $base-grey-5;
  border-radius: 8px;

  .content {
    padding: 24px;
    margin: 16px;
    background: $white;
    border: 1px solid $base-grey-10;
    border-radius: 8px;

    .inputContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .input {
        width: 48%;
      }
      .selectMenu{
        height: 15rem;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    bottom: 0;
    border-top: 1px solid $base-grey-10;
    padding: 2rem;
    background: $white;
    border-radius: 0px 0px 10px 10px;
  }
}
