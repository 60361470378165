@import 'assets/styles/mixins';

.impersonation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.8rem;
    background-color: $yellow;
    position: fixed;
    z-index: 1;
    width: 100%;

    .icon{
        color: $white;
        margin: 0.5rem
    }
}

.text {
    font-size: 1.5rem;
    font-weight: 500;
    color: $white;
    display: inline;
}

.button {
    font-size: 1.5rem;
    font-weight: 600;
    color: $white;
    display: inline;
    text-decoration: underline;
}
