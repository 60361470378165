.typeSelector {
  flex: 1;
}

.triggerRemove {
  flex: .2;
  border-radius: 6px;
  height: 4rem;
}

.leftSelect {
  div {
    &:first-of-type {
      border-radius: 6px 0 0 6px;
    }
  }
}

.middleSelect {
  flex: .7;
  div {
    &:first-of-type {
      border-radius: 0;
    }
  }
}

.rightSelect {
  div {
    &:first-of-type {
      border-radius: 0 6px 6px 0;
    }
  }
}