
@import 'assets/styles/mixins';

.container{
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .content{
    width: 100%;
    overflow-y: scroll;
    .header{
      @include viewportMobile {
        flex-direction: column;
      }
      .search{
        @include viewportMobile {
          width: 97% !important;
        }
      }
    }
  }

}

.root{
    margin-top: 3rem;
    .title{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 2rem;
      .headerContainer{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        .name{
          font-size: 2rem;
          font-family: urw-gothic, sans-serif;
          text-transform: capitalize;
        }
        .date{
          font-size: 12px;
          opacity: 0.8;
        }
        @include viewportMobile{
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
      .badge {
        margin-left: 0;
        font-size: 1rem;
        font-weight: 600;
      }

      @include viewportMobile {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        gap: 0rem;
      }

    }

    .block {
        margin-top: 1rem;
        width: 90%;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        row-gap: 2em;
        column-gap: 2em;
        margin-bottom: 32px;

        .card{
            margin-left: 4rem;

            @include viewportMobile {
              margin-left: 0rem !important;
            }
        }
      
        @include viewportMobile {
          grid-template-columns: 100%;
        }
      }
}

.subtitle{
  font-size: 16px;
  font-weight: 400;
}


.body{
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: $branding-background-color;
  margin: 3rem 2rem 3rem 0rem;

  .card{
    .text{
      font-size: 14px;
      text-align: center;
    }
    span{
      font-weight: 500;
    }
    background-color: $white;
    width: 100%;
    padding: 2rem;
  }
}
