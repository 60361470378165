@import 'assets/styles/variables';
@import 'assets/styles/mixins';

.root {
  position: relative;
  min-height: 100vh;
  background-color: $branding-background-color;
}

.header {
  padding: 3rem;
  background-color: $white;

  &WithDivider { border-bottom: 1px solid $base-grey-10; }
}

.title {
  display: flex;
  align-items: center;
}

.subtitle {
  color: $base-grey-50;
  @include text-h5;
  margin: 1rem 0;
}

.content {
  margin-left: 20rem;
  transition: margin .4s ease-in-out;

  &Expanded {
    margin-left: 10rem;
  }
}

.body {
  &WithMargin {
    padding: 3rem;
  } 
}