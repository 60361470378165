@import 'assets/styles/mixins';

:root {
  --PhoneInputCountrySelectArrow-color: #333333;
}

.root {
  :global(.PhoneInputInput) {
    @include input;
  }

  :global(.PhoneInputCountry) {
    @include input;
    width: 5em;
  }

  :global(.PhoneInputCountryIcon) {
    width: 30px;
    height: 20px;
    box-shadow: none;
    outline: none;
    background-color: transparent;
  }

  :global(.PhoneInputCountryIconImg) {
    border-radius: 4px;
  }

  :global(.PhoneInputCountrySelect):focus {
    & + :global(.PhoneInputCountryIcon) { 
      box-shadow: none;
    }
  }
}

.disabled {
  div {
    &:hover {
      border-color: $base-grey-10 !important;
    }
    
    select  {
      cursor: not-allowed !important;
    }
  }
  input {
    cursor: not-allowed;
    &:hover {
      border-color: $base-grey-10 !important;
    }
  }
}