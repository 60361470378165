@import 'assets/styles/mixins';

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6em;
  height: 3em;
  width: 20em;
  border-radius: 10px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: all .4s ease-in-out;

  &Disabled {
    cursor: not-allowed;
  }

  &:hover {
    filter: brightness(80%);
  }
}