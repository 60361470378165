@import 'assets/styles/variables';
@import 'assets/styles/colors';

.input {
  width: 100%;
  margin-top: $margin-default;

  input { background-color: $white !important; }
}

.configure {
  width: 100%;
}

.modal{
  width: 100%;
  height: 100%;
  padding: 1rem !important;

  iframe {
    width: 85vw;
    height: 90vh;
  }

  .customBorder {
    padding: 1rem 0 0 0 !important;
  }
}

.customFields{
  display: flex;
  justify-content: center;
  align-items: center;

  .actionButton {
    margin-top: 1.5rem;
    background-color: $base-grey-5;
    width: 40px;
    height: 100%;
    padding: 1rem;
    box-sizing: border-box;
    border: 1px solid $base-grey-10;
    border-radius: 0%;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    color: $base-grey-50;
    cursor: pointer;
    transition: all .2s ease-in-out;
  
    &:hover {
      background-color: $base-grey-5;
      color: $base-grey-50;
    }
  
    &:active {
      background-color: $brand-color;
      color: $white;
    }
  }
}
