@import 'assets/styles/variables';


.nav {

  &TabsWrapper {
    background-color: $white;
    border-bottom: 1px solid $base-grey-10;
    padding-left: 3rem;
    width: 100%;
    justify-content: left;
  }

  &Tabs {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
    margin: 0;
  }

  &Item {
    cursor: pointer;
    list-style: none;
    color: $base-grey-50;
    font-size: 1.8rem;
    font-weight: 400;
    transition: color .4s;
    -webkit-transition: color .4s;
    border: 1px solid transparent;
    background-color: $white;
    display: block;
    padding: .5rem 1rem;
    text-decoration: none;

    &Active,
    :hover {
      color: $brand-color;
      border-bottom: 2px solid $brand-color;
    }
  }
}

.tabContent {
  padding: 3rem;
}
