@import 'assets/styles/mixins';

.root {
  @include flexCenter;
  @include text-body;

  position: relative;
  height: 7em;
  width: 100%;
  background-color: $white;
  z-index: 99;
  border-top: 1px solid $base-grey-10;

  img {
    height: 20%;
    margin-left: .5rem;
  }

  @include viewportMobile {
    height: 4em;
    img {
      height: 40%;
    }
  }
}
