
@import 'assets/styles/mixins';

.root{
    margin-top: 1rem;
    width: 100%;
    height: 100%;

    .headerBackground{
        width: 100%;
        padding: 1rem 1rem 0rem 1rem ;
        background-color: $ensure-light;
        .header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: $snow-white;
            padding: 0rem 1rem;
            width: 100%;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;

            .iconName{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1rem;
            }
            .closeBtn {
                cursor: pointer;
                width: 2rem;
                height: 2rem;
                padding: .5rem;
                color: $base-grey-50;
                border-radius: 50%;
                background-color: $base-grey-5;
                border: 1px solid $base-grey-10;
                margin-left: 3rem;
              }
        }
    }
    .navigation{
        padding: 0 1rem 0rem 1rem;
        .content{
            background-color: $base-grey-5 ;
            padding: 1rem;
        }
        .activeTabColor{
            color: $ensure-dark;
            border-bottom: 2px solid $ensure-dark;
        }
    }
        
}


.header{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    p{
        font-size: 20px;
    }
}

.modal{
    padding: 1rem !important;
}
