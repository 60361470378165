@import 'assets/styles/mixins';

.root {
  .input:last-of-type {
    margin-bottom: 0;
  }

  .input {
    font-size: 12px;
  }
}
.error {
  margin: auto;
  width: 50%;
}


.button{
    background-color: $ensure-dark;
}