@import 'assets/styles/mixins';

.root {
  margin-top: 1rem;
  max-width: 80rem !important;
  min-width: 80rem !important;
  overflow-x: hidden;
  flex: 1;

  .content {
    height: 100%;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }

  .badge {
    margin-bottom: .7em;
  }

  .title {
    word-wrap: normal;
  }

  .embed {
    width: 100%;
    height: 60rem;
    margin-top: 2rem;
  }

  .embedMobile{
    width: 100%;
    height: 60%;
    margin-top: 2rem;
  }
}

.statement{
  white-space: pre-wrap !important;
}
.form {
  display: flex;
  flex-direction: column;
}

.next {
  width: 100%;
  margin-bottom: 2.4rem;
}

@include viewportMobile {
  .root {
    min-width: 90vw !important;
    display: flex;
    justify-content: center;
    margin-top: 0rem;
    border: 0px !important;

    .content {
      padding: 1.2rem;
    }
  }

  .next {
    width: 100%;
    margin-bottom: 2.4rem;
  }

  .signature {
    width: 25rem;

    img {
      width: 25rem;
    }
  }

  .embed {
    width: 100%;
    height: 30rem;
    margin-top: 2rem;
  }
}

.video {
  width: 100%;
  max-width: 98rem;
  margin: auto;
}

.infoItem {
  margin: 0 0 2.4rem 0 !important;
}


.description {
  font-size: 1.4rem;
}

.input {
  font-size: 1.8rem;
  text-align: justify;
  white-space: break-spaces;
  .description {
    font-size: 1.4rem;
  }
}

.blockBadge {
  justify-self: end;
  grid-column-start: 6;
  grid-column-end: 7;
  margin: 1rem 0;
  padding: 2px 12px 2px 12px;
  background-color: $base-grey-5;
  border: 1px solid $base-grey-10;
  border-radius: 100px;
  position: relative;
}

.active {
  &::after {
    content: '';
    position: absolute;
    top: -.3rem;
    z-index: 2;
    height: 2.4rem;
    width: 2.4rem;
    background: url(/assets/images/badgeactive.svg);
    background-size: 2.4rem;
    background-repeat: no-repeat;
  }
}

.inactive {
  &::after {
    content: '';
    position: absolute;
    top: -.3rem;
    z-index: 2;
    height: 2.4rem;
    width: 2.4rem;
    background: url(/assets//images/badgeinactive.svg);
    background-size: 2.4rem;
    background-repeat: no-repeat;
  }
}

.pdfBtn {
  width: 100%;
  a {
    color: white;
    text-decoration: none;
  }
}
