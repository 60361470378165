@import "assets/styles/colors";

@mixin content {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.root {
  margin-top: 3rem;
  .url {
    margin-top: 2rem;
    overflow: hidden;
    border-radius: 6px;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    iframe {
      @include content;
    }
  }
}
