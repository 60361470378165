@import 'assets/styles/mixins';

.root {

  .journeyContainer{
    padding: 3rem;
    .heading{
      font-size: 3.2rem;
      font-weight: 600;
      text-align: center;
      margin: 0px 0px 20px 0px;
    }
    .journeySelector {
      color: $brand-color;
      font-weight: 500;
      font-size: 16px;
    }
  }
}

