@import 'assets/styles/mixins';
@import 'assets/styles/colors';

.root {
  background-color: $white;
  width: 100%;
  padding: .95rem 0;
  border: 1px solid $base-grey-10;
  border-radius: 8px;
  display: grid;
  grid-template-columns: .1fr .7fr repeat(4, 1fr);
  align-items: center;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;

  .button {
    font-size: 1.5rem;
    font-weight: 600;
    color: $white;
    display: inline;
    text-decoration: underline;
    z-index: 2;
    cursor: pointer;
    margin-left: 1rem;
    &:hover {
      color: $brand-color;
    }
}
}

.checkbox{
  height: 1.8rem !important;
  width: 1.8rem !important;
}

.statusParent {
  grid-column-start: 2;
  margin-left: 1.3rem;

  .status {
    padding: 6px 1.2rem;
    text-transform: capitalize;
  }
  .archived{
    background-color: #E6E6E6 !important;
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
  }
}


.actions {
  justify-self: flex-end;
  padding: 0 1em;
  gap: 12px;
}

.checkbox {
  width: 2rem;
  margin-left: .8rem;
}

.name {
  position: relative;
  span {
    position: absolute;
    left: -2.5rem;
  }
}

.journeys {
  grid-column-start: 1;
  grid-column-end: 8;
  align-items: center;
  margin-top: 1.5rem;

  .journeyRow {
    border-top: 1px solid $base-grey-10;
    height: 5.6rem;
    padding: 0.95rem 0;
    display: grid;
    grid-template-columns: .1fr .7fr repeat(4, 1fr);
    align-items: center;
    font-size: 1.4rem;
    &:last-of-type {
      padding-top: .95rem;
    }
  }

  .journeyTitle {
    background-color: $base-grey-5;
    height: 4rem;
    padding: 0;
    div {
      grid-column-start: 2;
      grid-column-end: 5;
      margin: auto 0 auto 1.3rem;
    }

    .info{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;

      .badge{
        color: $white;
        font-weight: 500;
      }
    }
  }

  .blockName {
    position: relative;
    span {
      position: absolute;
      left: -2.5rem;
    }
  }

  .blockStatus {
    grid-column-start: 2;
    margin-left: 1.3rem;
    grid-column-end: 3;
  }

  .blockBadge {
    justify-self: end;
    grid-column-start: 6;
    grid-column-end: 7;
    margin-right: 4rem;
    padding: 2px 12px 2px 12px;
    background-color: $base-grey-5;
    border: 1px solid $base-grey-10;
    border-radius: 100px;
    position: relative;
  }

  .active {
    &::after {
      content: '';
      position: absolute;
      top: -1px;
      z-index: 2;
      height: 2.4rem;
      width: 2.4rem;
      background: url(/assets/images/badgeactive.svg);
      background-size: 2.4rem;
      background-repeat: no-repeat;
    }
  }

  .inactive {
    &::after {
      content: '';
      position: absolute;
      top: -1px;
      z-index: 2;
      height: 2.4rem;
      width: 2.4rem;
      background: url(/assets//images/badgeinactive.svg);
      background-size: 2.4rem;
      background-repeat: no-repeat;
    }
  }
}

.hide {
  z-index: -999;
}

