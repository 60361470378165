@import 'assets/styles/mixins';

.root {
  background-color: transparent;

  .error {
    padding: 1rem 0rem;
    position: relative;
    bottom: 0;
    left: 0;
    color: $error-color;
    font-size: 1.3rem;
    text-transform: capitalize;
    background-color: #f9f9f9 !important;
    &::before {
      content: '* ';
    }
  }
}

.blockTitle {
  @include text-h5;
  grid-column: 1 / span 11;
  width: 100%;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  margin: 0;

  .subTitle {
    margin-top: .3rem !important;
  }

  span {
    margin-right: .5rem;
  }
}

.selector {
  width: 100%;
  display: flex;
  min-height: 3.5rem;

  &Item {
    @include flexCenter;
    @include text-body;
    padding: .4rem;
    color: $base-grey-50;
    background-color: $base-grey-5;
    border: 1px solid $base-grey-10;
    cursor: pointer;
    transition: all .4 ease-in-out;
    width: 100%;

    &:first-of-type {
      border-top-left-radius: 3rem;
      border-bottom-left-radius: 3rem;
    }

    &:last-of-type {
      border-top-right-radius: 3rem;
      border-bottom-right-radius: 3rem;
      border-left: none;
    }
  }
}

.marginBottom {
  margin-bottom: 2rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.csv{
  display: flex;
  padding: 1rem;
  background-color: $base-grey-5 !important;
  border: 1px solid $base-grey-10 !important;

  .csvLengthBar{
    @include text-body;
    display: flex;
    align-items: center;
    background-color: $white !important;
    width: 90%;
    padding-left: 2rem;
    border: 1px solid $base-grey-10;
    border-radius: 6px 0px 0px 6px;
    font-weight: 400;
    .error{
      color: $error-color;
    }

  }
  .delete{
    line-height: 4rem;
    border-radius: 0 6px 6px 0;
    height: 4.2rem;
    width: 4rem;
  }
}

.uploadPdfContainer{
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  border-left: 3px solid rgb(73, 50, 208);
  background-color: #fff;
  padding: 1rem;
  .collapsibleBlock{
    min-width: 30rem !important;
  }

  .signatureCard{
    min-width: 100%;
    margin-top: 16px;
  }
  .order {
    @include flexCenter;

    width: 34px;
    height: 34px;
    color: $base-grey-50;
    background-color: $base-grey-5;
    border: 1px solid $base-grey-10;
    border-radius: 50%;
    cursor: pointer;
    transition: all .4s ease-in-out;
    font-weight: bolder;
    grid-column: 1 / span 1;
    justify-self: start;
  }

  .input {
    width: 100%;
    grid-column: 2/span 9;
  }

  .removeButton {
    grid-column: 11 / span 1;
    justify-self: end;
  }
}
