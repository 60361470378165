@import 'assets/styles/mixins';

.root {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;

  .canvas {
    border: 1px solid $base-grey-10;
    background-color: $base-grey-5;
    border-radius: 10px;
    width: 100%;
    height: 35rem;
    box-sizing: border-box;
  }

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $base-grey-10;
    background-color: $base-grey-5;
    border-radius: 10px;
    width: 100%;
    height: 35rem;
    box-sizing: border-box;

    .sign{
      max-width: 100% !important;
      max-height: 100% !important;
    }
  }

  .cancel {
    color: $base-grey-35;
    position: absolute;
    border-radius: 2.4rem;
    background-color: $base-grey-5;
    left: 0;
    width: 10rem;
    height: 4rem;
    margin: 1rem 0 0 1rem;
    z-index: 2;
    border: 1px solid $red;
    transition: .4s all;
    border-color: $base-grey-10;

    &:hover {
      background-color: $base-grey-10;
      border-color: $base-grey-25;
    }
  }

  .save {
    color: $white;
    position: absolute;
    right: 0;
    border-radius: 2.4rem;
    background-color: $green;
    padding: 0rem 0rem !important;
    width: 16rem;
    height: 4rem;
    margin: 1rem 1rem 0 0;
    border: 1px solid $green;
    transition: .4s all;

    &:hover {
      background-color: $green-150;
      color: $white;
    }
  }

  .load {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .error {
    position: relative;
    bottom: 0;
    left: 0;
    color: $error-color;
    font-size: 1.3rem;
    text-transform: capitalize;
    font-weight: bold;
  
    &::before {
      content: '* ';
    }
  }
  
}
