@import "assets/styles/colors";
@import "assets/styles/mixins";

.listItem {
  background-color: $white;
  border-radius: 8px;
  border: 1px solid $base-grey-10;
  padding: 2rem;

  .icons{
    gap: 1rem;
  }

  &.complete {
    border-left: 4px solid $green;
  }
  &.completed {
    border-left: 4px solid $green;
  }
  &.pending {
    border-left: 4px solid $yellow;
  }

  &.progress {
    border-left: 4px solid $brand-color;
  }

  .date {
    @include text-body;
    margin: 0;
    color: $base-grey-50;
  }

  .viewBtn {
    height: 3.2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .collapseToggle {
    min-width: 3.5rem;
  }
  .dropdownToggle {
    appearance: none;
    border: 0;
    background: none;
    padding: 0;
    &::after {
      content: none;
    }
  }

  .moreBtn {
    height: 3.5rem;
    width: 3.5rem;
  }

  .dropdownMenu {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 8px;
    border: 1px solid $base-grey-10;

    :last-child {
      color: $red;
    }
  }

  .menuItem {
    @include text-body;
    line-height: 2.3rem;
    width: -webkit-fill-available;
    &:hover {
      background-color: $brand-color-lightest;
    }
  }
}
