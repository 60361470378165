@import 'assets/styles/mixins';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.content {
  width: 100%;
  max-width: $max-width-content;
}