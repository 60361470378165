@import 'assets/styles/mixins';

.list {
  width: 35rem;
  min-height: calc(80.5vh - 30px);
  transition: height .35s ease;
}

.content {
  justify-content: stretch;
  height: 100%;
  transition: height .35s ease;

  .button{
    margin-left: auto;
    .contents{
      padding:1rem;
      width: 7rem;
    }
  }
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subtitle {
  width: 100%;
}

.items {
  background-color: $base-grey-5;
  border: 1px solid $base-grey-10;
  border-radius: 6px;
  width: 100%;
  padding: .5rem 0;
  margin-top: 1rem;
  height: 100%;
  overflow: scroll;
  transition: height .35s ease;

  .item {
    list-style: none;
    line-height: 3.6rem;
    padding: 0 1.5rem;
    transition: background-color .4s;
    cursor: pointer;
    display: flex;
    align-items: center;

    .checkBox{
      margin-right: 1rem;
      &:hover {
        background-color: $blue-20;
      }
    }
    
    .li{
      width: 100%;
    }

    &:hover {
      background-color: $base-grey-10;
    }
  }

  .itemHeader {
    color: $base-grey-50;
    padding: 0 1.5rem;
  }

  .largeItemHeader{
    color: $base-grey-50;
    padding: 0 1.5rem;
    font-size: 1.5em;
  }
}