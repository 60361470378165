@import 'assets/styles/mixins';

.root {
  width: 20rem;
  background: $snow-white;
  transition: width .4s ease-in-out;
  border: 1px solid $base-grey-10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;

  bottom: 0;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 2;

  .header{
    align-items: center;
    display: flex;
    padding: 0 3rem;
    flex: none;
    width: 100%;
    align-items: flex-start;
    justify-content: center;

    .headerLink{
      width: 100%;
      overflow: hidden;
      transition: all .4s ease-in-out;
      &Hidden {
        width: 2rem;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: auto;
    height: 100%;
    flex: 3;


  }

  .menu, .footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  .logo {
    width: 15rem;
  }

  .link {
    color: $base-grey;
    font-size: 1.6rem;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    margin: 5px 0;
    width: 75%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    transition: width .4s ease-in-out;
    border-radius: 6px;

    svg{
      opacity: 0.7;
      align-self: center;
    }

    &Active,
    &:hover {
      background: #F1EFFF;
      color: #4932D0 !important;
    }

    .title {
      align-self: center;
      opacity: 1;
      max-width: 100%;
      padding-left: 1rem;
      overflow: hidden;
      transition: all .4s ease-in-out;
      white-space: nowrap;
    }


    &Disabled {
      color: $base-grey-25;
      cursor: not-allowed;

      .title { color: $base-grey-25; }

      &:hover {
        background: none;
        color: $base-grey-25 !important;
      }
    }

    .img {
      color: $base-grey-50;
      margin: 0;
      height: 2.8rem;
      width: 2.8rem;
      border-radius: 50%;
      background: $snow-white;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #333333;
      font-size: 20px;
    }

    &Company {
      border: 1px solid #E6E6E6;
      border-radius: 12px;
      background: #F9F9F9;
      transition: all .1s ease-in-out;
      position:relative;
      .title {
        color: $base-grey;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 85px;
      }
      .downArrow {
        color: $base-grey-50;
        .companyMenu{
          position: absolute;
          top: 55px;
          right: 0px;
          width: fit-content;
          background: $white;
          border: 1px solid $base-grey-10;
          border-radius: 8px;
          padding: 1rem 0rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          width: 120px;
          overflow: hidden;
          .companyMenuLink{
            color: $base-grey-50;
            font-size: 14px;
            font-weight: 500;
            text-decoration: none;
            cursor: pointer;
            padding: 0.5rem 1rem;
            transition: all .2s ease-in-out;
            width: 100%;

            &:hover {
              background: #F1EFFF;
            }
          }
        }
      }
      .img{
        background: #9492FF;
        border: none;
        color: $white;
      }
    }

    &Active,
    &:hover {
      background: #F1EFFF;
      .title {
        color: #4932D0 !important;
      }
    }
  }

  .toggleButton {
    color: $base-grey-50;
    cursor: pointer;
    top: calc(50% - 1.8rem);
    position: absolute;
    right: -1.8rem;
    background-color: $base-grey-5;
    border-radius: 50%;
    border: 1px solid $base-grey-10;
    padding: .5rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    transition: transform .4s ease-in-out;
    z-index: 999999;
  }

  &Hidden {
    width: 10rem;

    .toggleButton {
      transform: rotateZ(180deg);
    }

    .link {
      width: 25%;

      .title {
        opacity: 0;
        max-width: 0;
        padding-left: 0;
      }
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: $branding-gradient;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $branding-gradient;
  }
}
