@import 'assets/styles/variables';

.root {
  position: fixed;
  background-color: transparent;
  overflow: hidden;
  z-index: 9999;
  bottom: -200%;
  right: 0;
  top: 0;
  left: 0;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem !important;

  transition: visibility .3s ease-in-out, bottom .3s ease-in-out, background-color .3s ease-in-out .1s;

  &Visible { 
    background-color: $overlay-color;
    visibility: visible;
    bottom: 0;
  }
}

.card {
  padding: 2.5rem;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.closeBtn {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  padding: .5rem;
  color: $base-grey-50;
  border-radius: 50%;
  background-color: $base-grey-5;
  border: 1px solid $base-grey-10;
  margin-left: 3rem;
}