.root {
  width: 50%;
  margin: auto;
  
  .header {
    .iconButton {
      position: absolute;
      right: 2.5rem;
      width: 4rem;
      height: 4rem;
    }
  }
  
}