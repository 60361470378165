@import 'assets/styles/variables';

.root {
  position: fixed;
  background-color: transparent;
  overflow: hidden;
  z-index: 9999;
  bottom: -200%;
  right: 0;
  top: 0;
  left: 0;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: visibility .3s ease-in-out, bottom .5s ease-in-out, background-color .3s ease-in-out .1s;

  &Visible { 
    background-color: $overlay-color;
    visibility: visible;
    bottom: 0;
  }

  .logo {
    width: 10rem;
    height: 10rem;
    animation: pulse 2s linear infinite;
  }
}

@keyframes pulse {
  0%    { transform: scale(1); }
  50%   { transform: scale(0.8); }
  100%  { transform: scale(1); }
}