@import 'assets/styles/variables';
@import 'assets/styles/mixins';

.root{
  background: #FAF9FF !important;
  .formContainer{
    background: $white;
    margin: 16px;
    border: 1px solid $base-grey-10;
    border-radius: 8px;
    padding-bottom: 4rem !important;
  }

  .input:last-of-type {
    margin-bottom: 0;
  }

  .input {
    font-size: 12px;
  }

  .selectMenu{
    height: 15rem;
  }

  .companyAvatar{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    span{
      width: 7.5rem;
      height: 7.5rem;
      background: $brand-color-light;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      font-size: 4rem;
      font-weight: 600;
    }
  }
  .footer{
    background: $white;
    border-radius: 0px 0px 10px 10px;
  }
}
