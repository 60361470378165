@import 'assets/styles/mixins';

.root {
  @include fullScreen;
  @include flexCenter;
  flex-direction: column;

  .content {
    padding: 6rem;
  }

  .logo {
    max-width: 25rem;
    max-height: 25rem;
  }

  .scopes {
    margin: 1em 0;
    width: 100%;
    list-style: none;

    li {
      @include text-body;
      padding: 1em 0;

      &:not(:last-of-type) {
        border-bottom: 1px solid $base-grey-10;
      }
    }
  }
}