@import 'assets/styles/mixins';

.stakeholderDetails {

  &Wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &Item {
    margin-top: 1.5rem;

    label {
      @include text-small;
    }

    p {
      @include text-small;
      margin-top: .5rem;
      margin-bottom: .5rem;
    }
  }

}
