@import 'assets/styles/mixins';

.activeTabColor{
    color: $ensure-dark;
    border-bottom: 2px solid $ensure-dark;
}

.tabsContent{
    padding: 0rem 0rem 3rem 3rem; /* top right bottom left */
}

.maxWidthContent{
    max-width: unset !important;
}


.container{
    display: flex;
    height: 100vh; /* Set the height of the container to fill the viewport */
    overflow: hidden;
}