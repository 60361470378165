@import 'assets/styles/mixins';

.root {
  position: relative;

  &WithError {
    padding-bottom: 1.5rem;
  }

  .lock,
  .icon {
    position: absolute;
    z-index: 1;
    color: $secondary-text-color;
    top: 2rem;
    margin-top: calc(-1.8rem / 2);
    margin-left: calc(1rem - 1.8rem / 2);
    height: 1.8rem;
    width: 2.8rem;
  }

  .lock {
    right: 2rem;
    color: black;
  }

  .input {
    @include input;

    &WithIcon { padding-left: 3rem; }

    &:read-only {
      cursor: copy;
    }
  }

  .disabled {
    opacity: 0.8;
    cursor: not-allowed !important;
  }

  .showPassword {
    @include link;

    font-size: 1.2rem;
    position: absolute;
    right: .7rem;
    line-height: 1.2rem;
    top: 1.5rem;
  }

  .error {
    position: absolute;
    bottom: 0;
    left: 0;
    color: $error-color;
    font-size: 1.1rem;
    text-transform: capitalize;

    &::before {
      content: '* ';
    }
  }

  .inputBar{
    position: relative;
  }

  .actionButton {
    position: absolute;
    top: 0px;
    right: -1px;
    background-color: $base-grey-5;
    width: 40px;
    height: 100%;
    padding: 1rem;
    box-sizing: border-box;
    border: 1px solid $base-grey-10;
    border-radius: 0%;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    color: $base-grey-50;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
      background-color: $base-grey-5;
      color: $base-grey-50;
    }

    &:active {
      background-color: $brand-color;
      color: $white;
    }
  }
  .input {
    &:hover {
      +.actionButton {
        border-left-color: #4932d0 !important;
      }
    }
  }
}
