@import 'assets/styles/mixins';

.contentContainer {
  @include viewportMobile {
    padding: 60px 20px 40px !important;
  }
}

.primaryText {
  &::before {
    left: 34.5% !important;
    background-size: 32% !important;
  }

  @include viewportMobile {
    &::before {
      left: 38% !important;
      top: 65% !important;
      background-size: 36% !important;
    }
  }
}

.input {
  @include viewportMobile {
    width: 270px;
    margin-right: 2rem !important;
  }
}

.messageContainer{
  margin: 0px 60px;
  .messageText {
    width: 280px;
    font-weight: 600;
    font-size: 32px;
    display: flex;
    margin: 32px 0px;
    align-items: center;
    text-align: center;
    color: $base-grey;
  }

  .messageDescription{
    font-size: 14px;
    width: 360px;
    font-weight: 400;
    text-align: center;
    margin: 0px;
  }
  .emailImg{
    margin-top: 24px;
    margin-bottom: 40px;
  }
}

.errorContainer {
  margin: 50px 100px;
}

.errorText {
  width: 315px;
  font-weight: 600;
  font-size: 32px;
  display: flex;
  align-items: center;
  text-align: center;

  /* UI/primary text */

  color: #333333;
}

.designMarker {
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    left: 110px;
    top: 65px;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/images/markup.svg);
    background-repeat: no-repeat;
    z-index: -1;

    &LinkSent{
      left: -10px;
      top: 21px;
    }
  }
}
.linkSent{
  &::before {
    left: -10px;
    top: 21px;
  }
}

.resendButton{
  z-index: 1;
  font-size: 18px;

  &:hover{
    text-decoration: underline;
  }
}
