/////////////////////////////////// UTILS /////////////////////////////////////

@import './mixins';

///////// Positioning /////////
.p-relative {
  position: relative;
}

///////// Flex /////////

.d-flex { display: flex }

.flex { 
  &-row    { flex-direction: row }
  &-column { flex-direction: column }
  &-10     { flex: 1  }
  &-07     { flex: .7 }
  &-05     { flex: .5 }
  &-03     { flex: .3 }
}

.justify-content {
  &-start   { justify-content: start }
  &-end     { justify-content: end }
  &-center  { justify-content: center }
  &-between { justify-content: space-between }
  &-around  { justify-content: space-around }
}

.align-items {
  &-start   { align-items: start }
  &-end     { align-items: end }
  &-center  { align-items: center }
  &-between { align-items: space-between }
  &-around  { align-items: space-around }
}

///////// Fonts /////////
.title-1 {
  @include text-h1;
  color: $base-grey;
  margin: 0;
}

.title-2 {
  @include text-h2;
  color: $base-grey;
  margin: 0;
}

.title-3 {
  @include text-h3;
  color: $base-grey;
  margin: 0;
}

.title-4 {
  @include text-h4;
  color: $base-grey;
  margin: 0;
}

.title-5 {
  @include text-h5;
  color: $base-grey;
  margin: 0;
}

.title-6 {
  @include text-h6;
  color: $base-grey;
  margin: 0;
}

.t-subtitle {
  @include text-body;
  color: $base-grey-50;
  margin: 0;
}

.t-body {
  @include text-body;
  color: $base-grey;
}

.t-small {
  @include text-small;
}

.t-tiny {
  @include text-tiny;
}

.t-color-secondary {
  color: $base-grey-50
}

.font-weight {
  &-100 { font-weight: 100; }
  &-400 { font-weight: 400; }
  &-500 { font-weight: 500; }
  &-600 { font-weight: 600; }
}

.t-capitalize {
  text-transform: capitalize;
}

///////// Forms /////////
form { 
  width: 100%;

  .inline {
    width: 100%;
    display: flex;
    justify-content: space-between;

    & > * {
      width: 48%;
    }
  }
}

///////// Components /////////
.card {
  @include card;
}

.link {
  @include link;
}

.link-button {
  @include link;
  @include flexCenter;
  
  font-size: 1.3rem;
  font-weight: 500;
  height: 40px;
  width: 100%;
  cursor: pointer;
  max-width: 50rem;
}

.button {
  @include button;
}

///////// Design /////////
.design-marker {
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 55%;
    width: 100%;
    height: 100%;
    background-image: url(../images/markup.svg);
    background-repeat: no-repeat;
    z-index: -1;
  }
}

///////// Colors /////////
.background-secondary {
  background-color: $base-grey-5;
}

///////// Margins /////////
.margin {
  margin: $margin-default;

  &--small { margin: $margin-small }
  &--large { margin: $margin-large }

  &-0 {
    margin: 0;
  }

  &-top {
    &-0      { margin-top: 0 }
    margin-top: $margin-default;
    &--small { margin-top: $margin-small }
    &--large { margin-top: $margin-large }
  }

  &-bottom {
    &-0      { margin-bottom: 0 }
    margin-bottom: $margin-default;
    &--small { margin-bottom: $margin-small }
    &--large { margin-bottom: $margin-large }
  }

  &-y {
    margin-top: $margin-default;
    margin-bottom: $margin-default;

    &--small { 
      margin-top: $margin-default;
      margin-bottom: $margin-default;
    }

    &--large { 
      margin-top: $margin-default;
      margin-bottom: $margin-default;
    }
  }

  &-right {
    margin-right: $margin-default;
    &--small { margin-right: $margin-small }
    &--large { margin-right: $margin-large }
  }

  &-left {
    margin-left: $margin-default;
    &--small { margin-left: $margin-small }
    &--large { margin-left: $margin-large }
  }

  &-x {
    margin-right: $margin-default;
    margin-left: $margin-default;

    &--small { 
      margin-right: $margin-default;
      margin-left: $margin-default;
    }
    
    &--large { 
      margin-right: $margin-default;
      margin-left: $margin-default;
    }
  }
}

.padding {
  padding: $margin-default;
  
  &--small { margin: $margin-small }
  &--large { margin: $margin-large }

  &-top {
    padding-top: $margin-default;
    &--small { padding-top: $margin-small }
    &--large { padding-top: $margin-large }
  }

  &-bottom {
    padding-bottom: $margin-default;
    &-0      { padding-bottom: 0 }
    &--small { padding-bottom: $margin-small }
    &--large { padding-bottom: $margin-large }
  }

  &-right {
    padding-right: $margin-default;
    &--small { padding-right: $margin-small }
    &--large { padding-right: $margin-large }
  }

  &-left {
    padding-left: $margin-default;
    &--small { padding-left: $margin-small }
    &--large { padding-left: $margin-large }
  }

  &-0 {
    padding: 0;
  }
}

.height {
  &-100 {height: 100%;}
}
