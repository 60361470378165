.container {
  margin-top: 2rem;
  .selectBar {
    margin: auto;
    width: 50%;
  }
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

@import "assets/styles/mixins";

.tableHeaders {
  width: 100%;
  display: grid;
  grid-template-columns: 0.1fr 0.7fr repeat(4, 1fr);
  align-items: center;
  font-size: 1.2rem;
  background-color: $white;
  padding: 2rem 3.5rem;
  border-bottom: 1px solid $base-grey-10;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  span {
    @include text-body;
    font-size: 1.5rem !important;
    font-weight: 600 !important;
  }
}
.notFound{
  padding: 1rem;
  text-align: center;
  font-size: 2rem;
}

.tableRows {
  padding: 3rem;
}

.select {
  width: 16rem;
}

.statusParent {
  grid-column-start: 2;
}

.message {
  height: 60vh;
}

.addBtn {
  width: 13rem;
  height: 4.5rem;
}

.centerBtn {
  height: 4.5rem;
}

.centerTitle {
  font-size: 2rem;
}

.border {
  border-radius: 10px;
}

.header {
  .searchForm {
    display: flex;
    align-items: center;
  }
}
