@import 'assets/styles/mixins';

.header{
  border-radius: 10px;
  padding: 16px;
}

.body{
  margin-top: 20px;
  background: $base-grey-5;
  border-radius: 10px;
  overflow: hidden;
}

.checkboxBar {
  width: 100%;
  border-radius: 15px;
  padding: 1rem 3rem 0rem 3rem;

  .bar {
    height: auto;
    background: #F1EFFF;
    border: 1px solid $base-grey-10;
    border-radius: 8px;
    position: relative;

    .content {
      margin-left: 2rem;
      padding: 0.5rem;
      display: flex;
      justify-content: space-between;

      .deleteUsers {
        margin-right: 0.5rem;
        margin-top: 0.1rem;

        .button {
          background-color: $red;
          padding: 0rem 2rem !important;
        }
      }

      .text {
        font-family: 'Inter';
        font-style: normal;
        font-size: 14px;
        font-weight: 400;
        color: $red;
        padding-top: 1.2rem;
      }
    }
    .arrowDown {
      position: absolute;
      width: 15px;
      height: 15px;
      transform: rotate(45deg);
      left: 11px;
      bottom: -8px;
      background: #F1EFFF;
      border-right: 1px solid $base-grey-10;
      border-bottom: 1px solid $base-grey-10;
    }
  }
}
.tableHeaders {
  width: 100%;
  display: grid;
  grid-template-columns: 0.3fr 0.7fr 1.5fr 1.5fr repeat(3, 1fr);
  align-items: center;
  background-color: $white;
  padding: 16px 18px;
  border-bottom: 1px solid $base-grey-10;
  border-radius: 10px 10px 0px 0px;

  span {
    @include text-body;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0px 10px;
  }
  &WithoutCompany {
    grid-template-columns: 0.3fr 1.5fr 1.5fr repeat(3, 1fr);
  }
}

.searchForm{
  display: flex;
  align-items: center;
  .searchInput{
    width:30%;
  }
}

.tableRows {
  margin: 16px;
  overflow: auto;
}

.select {
  width: 16rem;
}

.filter {
  cursor: pointer;
}
.roles{
  padding-right: 3rem;
}


.message {
  height: 60vh;
}

.addBtn {
  width: 13rem;
  height: 4.5rem;
}

.signInBtn {
  width: 20rem;
  height: 4.5rem;
  margin-right: 10px;
  background-color: $green !important;
}


.centerBtn {
  height: 4.5rem;
}

.centerTitle {
  font-size: 2rem;
}

.companyDropdown{
  margin-left: 3rem;
  font-size: 1.4rem;
  font-weight: 400;
  color: $base-grey-50;
}

.tabContent{
  padding: 3rem;
}
