/////////////////////////////////// COLORS /////////////////////////////////////

///////// Brand /////////
$brand-color-dark:        #2A15AC   !default;
$brand-color:             #4932d0   !default;
$brand-color-light:       #a499e7   !default;
$brand-color-lightest:    #4932D01A !default;


///////// Colors /////////
$white:                   #FFFFFF !default;
$snow-white:              #FDFDFD !default;
$black:                   #000000 !default;

$red:                     #EB5757 !default;
$red-20:                  rgba(235, 87, 87, 0.2) !default;
$red-dark:                #e92727 !default;

$green-150:               #02722f;
$green:                   #039D41;
$green-10:                rgba(46, 191, 94, 0.1);

$yellow:                  #cfb000;
$yellow-20:               rgba(224, 192, 11, 0.2);

$blue:                    #008CCE;
$blue-20:                 rgba(0, 140, 206, 0.2);

$base-grey-5:             #f9f9f9 !default;
$base-grey-10:            #e5e5e5 !default;
$base-grey-25:            #999999 !default;
$base-grey-35:            #777777 !default;
$base-grey-50:            #666666 !default;
$base-grey:               #333333 !default;


$ensure-light:            #f0f8f9 !default;
$ensure-dark:             #1f4f5c !default;