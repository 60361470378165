@import 'assets/styles/mixins';

.root {
  height: 100%;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  
  .icons{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.content {
  border-top: 1px solid $base-grey-10;
}

.subtitle {
  margin-top: 1.3rem;
}

.error {
  width: 100%;
  .errorText {
    color: $error-color;
    font-size: 1.3rem;
    text-transform: capitalize;
    margin-bottom: 1rem;
    margin-right: 2rem;
    &::before {
      content: '* ';
    }
  }
}

.footer{
  padding: 2rem 2rem 1rem 2rem !important;
}
