@import 'assets/styles/mixins';

.root {
  padding: 1rem;
  width: 100%;

  &Opened {
    .header .toggleButton {
      transform: rotate(180deg);
    }
  }
}

.header {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 1rem;
  justify-items: center;
  align-items: center;
  height: 5.4rem;

  &FullWith {
    & > * {
      grid-column-start: span 11;
      justify-self: start;
    }
  }

  .iconsWithAction{
    grid-column-start: span 2 !important;
    align-items: center !important;
  }
  .icons{
    display: flex;
    grid-column-start: 12;
    gap: 1.5rem;
    justify-self: end;
  }
  
}

.toggleButton {
  transform: rotate(0deg);
  transition: transform .4s, opacity .4s 0s;
}