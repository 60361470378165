@import 'assets/styles/mixins';

.root{
  position: relative;
  width: 100%;
  .selectContainer{
    width: 100%;
  }

  .optionLabel{
    font-size: 14px;
    font-weight: 400;
  }
  .errorText {
    color: #f44336;
    position: absolute;
    font-weight: 500;
    font-size: 11px;
    bottom: -17px;
    left: 0px;
  }

}
