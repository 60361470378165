@import 'assets/styles/colors';

.root {
  margin-right: 3rem;
  .header {
    border-bottom: 1px solid $base-grey-10 ;
    padding: 2.4rem;

    .title {
      svg {
        margin-bottom: .4rem;
      }
    }

    .editBtn {
      width: 6rem;
      height: 3.2rem;
      padding: 0;
    }
  }

  .body {
    padding: 2.4rem;

    .photoDiv {
      text-align: center;
      .img {
        color: $brand-color;
        opacity: .7;
        margin: .5rem 0 0 0;
        height: 4.8rem;
        width: 4.8rem;
        border-radius: 50%;
        background-color: $base-grey-10;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    
    .data {
      width: 100%;
    }
  }
}