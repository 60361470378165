@import 'assets/styles/mixins';

.root {
  min-height: 65vh;
  margin-top: 1rem;
  max-width: 80rem !important;
  min-width: 80rem !important;
  padding: 2rem;
  flex: 1;
  overflow-y: scroll;

  @include viewportMobile {
   margin-top: 0rem !important;
   border: 0px !important;
  }


  .image,
  .video {
    width: 100%;
    max-width: 98rem;
    margin: auto;
  }

  .blockItems {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      align-self: flex-start;
    }
  }

  .infoItem {
    margin: 0 0 2.4rem 0 !important;
    align-self: flex-start;
    text-align: left !important;
  }

  .infoTextItem {
    margin: 0 0 2.4rem 0 !important;
    text-align: justify !important;
    white-space: pre-wrap !important;
    line-height: 1.5 !important;
    width: 100%;
  }

  .heading {
    h1 {
      margin-top: 1rem;
    }
  }

  .blockInfo {
    padding: 2rem 2rem 0 2rem;

    .badge {
      margin-left: 0;
      font-size: 1.2em;
      font-weight: 600;
      margin-bottom: .7em;
    }

    h1 {
      margin-top: 2rem;
    }
  }

  .embed {
    height: 50rem;
    padding: .2rem;
    width: 100%;
    embed {
      width: 100%;
      height: 50rem;
    }
  }
  .embedMobile{
    width: 100%;
    height: 60%;
    margin-top: 2rem;
  }

  .tangoLink {
    width: 100%;
    a {
      color: white;
      text-decoration: none;
    }
  }

  .next {
    width: 100%;
    margin-bottom: 2.4rem;
  }

  @include viewportMobile {
    min-width: 90vw !important;
    max-height: 80vh !important;
    overflow: scroll;
    padding: 1.2rem !important;

    .heading {
      margin-bottom: 2.4rem;
      h1 {
        margin-top: .5rem;
      }
    }

    .image {
      min-width: 100%;
      max-width: 10rem;
    }

    .infoItem{
      margin: 0 0 2.4rem 0 !important;
      text-align: justify !important;
    }

    .infoTextItem {
      margin: 0 0 2.4rem 0 !important;
      text-align: justify !important;
      white-space: pre-wrap !important;
      line-height: 1.5 !important;
    }

    .next {
      width: 100%;
      margin-bottom: 2.4rem;
    }

    .blockItems {
      padding: 0;
    }
  }
}

