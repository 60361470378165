@import 'assets/styles/mixins';

.root {
    width: 50%;
    margin: auto;
    
    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .switch{
        width: 6rem !important;
        display: flex;
        justify-content: flex-end;
      }
    }
    .subHeading{
        margin-top: 2rem;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 3rem;
        .info{
          font-weight: 500;
          font-size: 15px;
        }
    }

    .svg{
      width: 100%;
    }

    .line{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 3rem;

      .circle{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $brand-color-light;
        margin-right: 2rem;
      }
      .text{
        font-size: 16px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
      }
    }

    .code{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
        input{
          border-top: $white !important;
          border-left: $white !important;
          border-right: $white !important;
          margin-right: 2rem;
          border-radius: 0rem !important;
          width: 4rem !important;
        }
    }

  }