.container {
  overflow: hidden;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  width: 100%;

  .frame {
    width: 100%;
    height: 100%;
  }
} 