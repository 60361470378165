@import 'assets/styles/mixins';

.error {
    position: relative;
    bottom: 0;
    left: 0;
    color: $error-color;
    font-size: 1.1rem;
    text-transform: capitalize;

    &::before {
        content: '* ';
    }
}