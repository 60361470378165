@import 'assets/styles/mixins';


.header {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  width: 100%;
  height: 7rem;
  z-index: 99;
  border-bottom: 1px solid $base-grey-10;

  .navItems{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0rem 2rem;

    .logoContainer{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .logo {
        width: 6rem;
      }
      .defaultLogo {
        height: 6rem;
        width: 12rem;
      }
    }

    .ctaButton {
      height: 2.5em;
      width: 10em;
    }
  }

  @include viewportMobile {
    flex-wrap: wrap;
    position: relative;
    z-index: 0;
    justify-content: space-between;

    .logo,
    .defaultLogo {
      max-height: 60%;
    }

    .ctaButton {
      height: 2.5em;
      width: 9em;
      min-width: 10em;
      font-size: 1.4rem;
    }

    .journey {
      display: none;
    }
  }
}


