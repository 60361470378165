@import 'assets/styles/mixins';

.root {
  @include fullScreen;
  @include flexCenter;
  flex-direction: column;
  
  .forgotPassword {
    position: absolute;
    top: -0.6rem;
    right: 0;
    font-size: 1.1rem;
  }

  .content {
    padding: 6rem;
  }

  @media (max-width: 768px){
    .content {
      padding: 6rem 2rem;
    } 
  }
}