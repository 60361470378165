@import '../../../../assets/styles/colors';

.root {
  .button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 43px;
    box-shadow: none !important;
    border: 1px solid $base-grey-10 !important;
    border-radius: 6px !important;
    div {
      position: absolute;
      background-color: transparent !important;
      left: 4px;
      top: 2px;
    }
  }
  .buttonPosition{
    width: 41rem;
  }
  @media (max-width: 768px){
    .buttonPosition{
      width: 24rem;
    }
  }
  .border {
    display: flex;
    justify-content: center;
    position: relative;
    margin: 1rem 0;
    color: $base-grey-50;
    &::after {
      content: '';
      position: absolute;
      width: 19.5rem;
      height: 1px;
      background-color: $base-grey-10;
      right: 0;
      top: .8rem;
    }
    &::before {
      content: '';
      position: absolute;
      width: 19.5rem;
      height: 1px;
      background-color: $base-grey-10;
      left: 0;
      top: .8rem;
    }
  }
}