@import "assets/styles/colors";
@import "assets/styles/mixins";

.root {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  .header {
    padding: 2.4rem;

    .title {
      width: 100%;
      svg {
        margin-bottom: 0.4rem;
      }
    }

    .downloadBtn {
      min-width: 12rem;
      height: 3.2rem;
      padding: 0;
    }

    .toggleButton {
      min-width: 3.5rem;
    }
  }
  .body {
    background-color: $base-grey-5;
    border-bottom: 1px solid $base-grey-10;
    .no_padding {
      padding: 0rem !important;
    }
    .tabsContent {
      margin-top: 1.5rem;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
  }
}
