@import '../../../assets/styles/mixins';

.root {
  width: 100%;
  display: flex;
  height: 4rem;
  border: 1px solid $base-grey-10;
  border-radius: 10rem;
  overflow: hidden;

  .option {
    @include text-body;
    color: $base-grey-50;
    flex-grow: 1;
    text-align: center;
    height: 100%;
    line-height: 3.8rem;
    background-color: $base-grey-5;
    cursor: pointer;
    font-weight: 600;
    transition: 0.4s ease-in-out;

    &:not(:last-of-type) {
      border-right: 1px solid $base-grey-10;
    }

    &Active {
      color: $white;
    }
  }
}