@import 'assets/styles/mixins';

.root{
  background-color: $base-grey-5;
}

.header{
  padding: 16px;
}


.selectContainer{
  padding: 16px;
  background-color: $white;
  border: 1px solid $base-grey-10;
  border-radius: 10px;

}

.tableHeaders {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  background-color: $white;
  border-top: 1px solid $base-grey-10;
  border-bottom: 1px solid $base-grey-10;
  padding: 16px 26px;

  span {
    @include text-body;
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.searchForm {
  width: 25%;
}

.tableRowsContainer{
  padding: 16px;
  .tableRows:last-child {
    margin-bottom: 0px;
  }
}


.tableRows {
  background-color: $white;
  width: 100%;
  padding: .95rem 0;
  border: 1px solid $base-grey-10;
  border-radius: 8px;
  display: grid;
  grid-template-columns:1fr 1fr 1fr;
  align-items: center;
  font-size: 1.4rem;
  margin-bottom: 16px;
}

.actions {
  justify-self: flex-end;
  padding: 0 16px;
}

.name {
  padding-left: 10px;
}
