@import 'assets/styles/mixins';

@mixin content {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.root {
  margin-top: 3rem;
  .vimeo {
    margin-top: 2rem;
    overflow: hidden;
    border-radius: 6px;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    iframe {
      @include content;
    }
  }
}

.error {
    position: relative;
    bottom: 0;
    left: 0;
    color: $error-color;
    font-size: 1.3rem;
    text-transform: capitalize;
  
    &::before {
      content: '* ';
    }
  }
