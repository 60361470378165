/////////////////////////////////// VARIABLES /////////////////////////////////////

@import './colors';

///////// Colors /////////
$branding-gradient:             linear-gradient(116.5deg, #7D2BE5 4.39%, #351FBA 72.66%) !default;
$branding-gradient-with-opacity: linear-gradient(116.5deg, rgba(125, 43, 229, 0.25) 4.39%, rgba(53, 31, 186, 0.25) 72.66%) !default;
$branding-background-color:    #FAF9FF !default;

$ensure-gradient: linear-gradient(116.5deg, #469db2, #1f4f5c);

$overlay-color:                #33373bf2;

///////// Text colors /////////
$primary-text-color:    $base-grey;
$secondary-text-color:  $base-grey-50;

$error-color:           $red;
$error-color-light:     $red-20;
$success-color:         $green;
$success-color-light:   $green-10;
$info-color:            $yellow;
$info-color-light:      $yellow-20;

///////// Layout /////////
$max-width-content:     1280px !default;

///////// Margins /////////
$margin-small:          .7rem !default;
$margin-default:        1.5rem !default;
$margin-large:          3rem !default;

///////// Statuses colors /////////
$text-color:                          $white;
$pending-color-text:                  $yellow;
$pending-color-background:            $yellow;
$pending-color-light-background:      $yellow-20;

$in_progress-color-text:              $blue;
$in_progress-color-background:        $blue;
$in_progress-color-light-background:  $blue-20;

$completed-color-text:                $green;
$completed-color-background:          $green;
$completed-color-light-background:    $green-10;
