
.root{
    padding: 2rem;
    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .text{
            font-size: 15px;
        }
    }
    .select{
        margin-bottom: 3rem;
    }
    
}