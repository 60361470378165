@import 'assets/styles/mixins';

.root {
  background-color: $white;
  width: 100%;
  padding: 1.2rem;
  border: 1px solid $base-grey-10;
  border-radius: 8px;
  display: grid;
  grid-template-columns: .3fr repeat(3, 1fr);
  align-items: center;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
}

.name {
  position: relative;
  span {
    position: absolute;
    left: -2.5rem;
  }
}

.csv {
  text-decoration: none;
  position: absolute;
  right: 5rem;
}

.toggleButton {
  text-decoration: none;
  position: absolute;
  right: 5rem;
}