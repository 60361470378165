@import "assets/styles/mixins";

.blockName{
  white-space: nowrap;
}

.select {
  min-width: 12rem;
}

.select2 {
  min-width: 25rem;
}
.optionLabel{
  color: $base-grey;
  min-width: 3rem !important;
  height: 3rem !important;
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.tableHeaders {
  width: 100%;
  display: grid;
  grid-template-columns: 0.3fr repeat(3, 1fr);
  align-items: center;
  font-size: 1.2rem;
  background-color: $white;
  padding: 0.5rem 3rem;
  border-bottom: 1px solid $base-grey-10;

  span {
    @include text-body;
  }

  .filter {
    cursor: pointer;
  }
}

.rowsDiv {
  position: relative;
  padding: 3rem;
}

.downloadButtons {
  display: flex;
  justify-content: center;
  .downloadButton {
    margin-right: 1rem;
    bottom: 0;
    left: calc(50% - 10rem);
    padding: 1rem;

    .downloadText {
      background-color: $brand-color-lightest;
      color: $brand-color;
      border-radius: 10rem;
      padding: 0.5rem 1rem;
    }

    &:hover {
      .downloadText {
        color: white;
        background-color: $brand-color-dark;
      }
    }
  }
}
