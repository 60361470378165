@import 'assets/styles/mixins';

.root{
    .content{
        max-height: 50rem;
        overflow: scroll;
    
        .input{
            margin: 1rem;
        }
        .item {
            list-style: none;
            line-height: 3.6rem;
            padding: 0 1.5rem;
            transition: background-color .4s;
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-top: 1rem;
        
            .checkBox{
              margin-right: 1rem;
              &:hover {
                background-color: $blue-20;
              }
            }
            
            .li{
              width: 100%;
            }
        
            &:hover {
              background-color: $base-grey-10;
            }
          }
    }
    
    .buttonDiv {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 2rem;
      
        border-top: 1px solid $base-grey-10;
      }
      
}
