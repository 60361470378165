@import 'assets/styles/mixins';

.root{
  background-color: $base-grey-5;
  padding: 16px;
}


.permissionContainer{
  padding: 16px;
  background-color: $white;
  border: 1px solid $base-grey-10;
  border-radius: 8px;

  .description{
    color: $base-grey-50;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 10px;
    padding-bottom: 1rem;
    border-bottom: 1px solid $base-grey-10;
  }
  p:last-child{
    margin-bottom: 0px;
    border: none;
    padding: 0;
  }
}
