.input{
    margin-top: 3rem;
    margin-bottom: 1.5rem;
  }
  .playerContainer{
     
      iframe {
          border: 1px solid white;
          border-radius: 5px;
          width: 100% !important;
          height: 100% !important;
        }
  }