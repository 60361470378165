@import 'assets/styles/mixins';

.root {
  position: relative;
  @include flexCenter;
  @include input;
  height: 10em;

  &:hover {
    .removeFileIconWrapper {
      opacity: 1;
    }
  }

  svg {
    color: $base-grey-50;
  }
}

.disabled {
  &:hover {
    .removeFileIconWrapper {
      opacity: 0;
      cursor: not-allowed;
    }
  }
}

.error {
  position: relative;
  bottom: 0;
  left: 0;
  color: $error-color;
  font-size: 1.1rem;
  text-transform: capitalize;

  &::before {
    content: '* ';
  }
}

.dropzone {
  @include flexCenter;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.placeholder {
  color: $base-grey-35;
}

.icon {
  color: $base-grey-35;
}

.removeFileIconWrapper {
  @include flexCenter;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  z-index: 0;
  background-color: #6b6b6b6e;
  cursor: pointer;
  transition: opacity .4s;

  .removeFileIcon {
    padding: 13px;
    background-color: $error-color;
    border-radius: 50%;
    color: $white;
  }
}
