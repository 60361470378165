@import 'assets/styles/mixins';

.root {
  width: 100%;
  position: relative;
  color: $secondary-text-color;

  .title {
    display: block;
    font-weight: 500;
    font-size: 1.4rem;
    margin-bottom: .7em;

    &Strong {
      font-weight: 600;
      font-size: 1.8rem;
      color: $base-grey;
    }

    &Error {
      color: $error-color;
    }
  }

  &Inline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .7em;

    .input {
      width: 30em;
    }

    .title {
      @include text-body;
      margin-bottom: 0;
      color: $base-grey;
    }
  }
}
