@import 'assets/styles/mixins';

.root {
  margin-top: 2.4rem;
}

.link {
  color: $brand-color;
  text-decoration: none;
  margin-left: .3rem;
  font-size: 1.3rem;
}

.embed {
  height: 40rem;
  padding: .2rem;
  embed {
    width: 100%;
    height: 100%;
  }
}