@import 'assets/styles/variables';
@import 'assets/styles/mixins';

.root {
  width: 50%;
  background: #FAF9FF !important;
  margin: auto;

  .header {
    background: $white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px 28px;
    border-bottom: 1px solid $base-grey-10;
    border-radius: 10px 10px 0px 0px;
    .iconButton {
      position: absolute;
      right: 2.5rem;
      width: 4rem;
      height: 4rem;
    }
  }

  .title{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: $base-grey;
    margin: 0;
  }
  .subtitle {
  color: $base-grey-50;
  font-size: 14px;
  margin: 4px 0px;
}

}
