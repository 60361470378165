@import 'assets/styles/mixins';

.root {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  margin: auto;

  .block {
    flex: 1;
    margin-top: 10rem;
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;
  }

  @include viewportMobile {
    height: 100%;
    margin-top: 6.5rem;

    .block {
      margin-top: 0;
    }
  }
}

.pdfFormContainer {
  margin-top: 1rem;
  min-width: 80rem !important;
  max-width: 100rem;
  overflow-x: hidden;
  flex: 1;

  .content {
    height: 100%;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }

  .badge {
    margin-left: 0;
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: .7em;
  }

  .title {
    word-wrap: normal;
  }

  .embed {
    width: 100%;
    height: 60rem;
    margin-top: 2rem;
  }

  .embedMobile{
    width: 100%;
    height: 60%;
    margin-top: 2rem;
  }
  .blockBadge {
    justify-self: end;
    grid-column-start: 6;
    grid-column-end: 7;
    margin: 1rem 0;
    padding: 2px 12px 2px 12px;
    background-color: $base-grey-5;
    border: 1px solid $base-grey-10;
    border-radius: 100px;
    position: relative;
  }

  .active {
    &::after {
      content: '';
      position: absolute;
      top: -.3rem;
      z-index: 2;
      height: 2.4rem;
      width: 2.4rem;
      background: url(/assets/images/badgeactive.svg);
      background-size: 2.4rem;
      background-repeat: no-repeat;
    }
  }

  .inactive {
    &::after {
      content: '';
      position: absolute;
      top: -.3rem;
      z-index: 2;
      height: 2.4rem;
      width: 2.4rem;
      background: url(/assets//images/badgeinactive.svg);
      background-size: 2.4rem;
      background-repeat: no-repeat;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
  }
  .next {
    width: 100%;
    margin-bottom: 2.4rem;
  }
}

@include viewportMobile {
  .pdfFormContainer {
    min-width: 90vw !important;
    display: flex;
    justify-content: center;

    .content {
      padding: 1.2rem;
    }
  }

  .next {
    width: 100%;
    margin-bottom: 2.4rem;
  }

  .signature {
    width: 25rem;

    img {
      width: 25rem;
    }
  }

  .embed {
    width: 100%;
    height: 30rem;
    margin-top: 2rem;
  }
}
