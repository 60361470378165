@import 'assets/styles/mixins';

.heading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    min-height: 15rem;
    margin-top: 10rem;

    .title {
      display: flex;
      align-items: center;
      margin: 1.2rem 1.2em 1.2rem 0;
      min-width: 40%;
      max-width: 100%;
      font-size: calc(1rem + 1.5vw);
      white-space: pre-line;
    }

    .subtitle{

        @media (max-width: 768px){
            text-align: center;
            font-size: calc(1rem + 1.5vw);
          }
        span{
            color: rgb(73, 50, 208);
        }
    }
} 