@import 'assets/styles/mixins';

.root{
    width: 100%;
    .input{
        margin-top: 3rem;
        margin-bottom: 1.5rem;
    }
    
    .container{
        .iframe{
            max-width: 853px;
            max-height: 480px;
            border: 1px solid;
            border-radius: 6px;
        }
    } 
    
    .error {
        margin-top: 1rem;
        position: relative;
        bottom: 0;
        left: 0;
        color: $error-color;
        font-size: 1.3rem;
        text-transform: capitalize;
      
        &::before {
          content: '* ';
        }
      }
}
