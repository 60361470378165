@import 'assets/styles/colors';
@import 'assets/styles/mixins';

.root {
  height: 100%;
}

.actions{
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.headerContainer{
  z-index: 999;
  position: relative;
  background: white;
  border-radius: 8px 8px 0px 0px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.content {
  border-top: 1px solid $base-grey-10;
}

.logic {
  padding: 2rem;
}

.unlockSubtitle{
  font-size: 1.4rem;
  font-weight: 400;
  margin: 0;
  margin-top: 1rem;
  width: 100%;
  color: $error-color
}
.hideSettings{
  display: none;
}
.blockWrapper{

  @include flexCenter;
  position: absolute;
  top: 0px;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  z-index: 1;
  background: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-radius: 8px;
  transition: opacity .4s;

  .unlockBlock {
    padding: 1rem;
    overflow: visible;
    background: $error-color;
    border-radius: 50%;
    color: $white;
    z-index: 99;
    transition: padding  0.15s ease-in-out ;
  }
  .unlockBlock:hover {
    padding: 1.2rem;
    background: $brand-color-dark;
  }
}
