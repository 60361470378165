.typeSelector {
  flex: 1;
}

.triggerRemove {
  flex: .2;
  border-radius: 0 6px 6px 0;
  height: 4rem;
}

.multipleSelect {
  flex: 1;
  div {
    border-radius: 0;
  }
  
}

.leftSelect {
  div {
    border-radius: 6px 0 0 6px;
  }
}

.multiSelectHeight{
  line-height: 3.8rem !important;
}


.addBtn {
  height: 1rem;
  margin: 1.5rem 0 .5rem 0;
}