@import 'assets/styles/mixins';

.root{
  padding: 1rem 0rem;
  .card{
    background-color: $base-grey-5;
    border: 1px solid $base-grey-10;
    border-radius: 6px;

    .item {
        list-style: none;
        line-height: 3.6rem;
        padding: 0 1.5rem;
        transition: background-color .4s;
        display: flex;
        align-items: center;

        .checkBox{
          margin-right: 1rem;
          &:hover {
            background-color: $blue-20;
            cursor: pointer;
          }
        }
        
        .li{
          width: 100%;
          padding: 0.5rem;
        }

        &:hover {
          background-color: $base-grey-10;
        }
      }
  }
}

.notFound{
  display: flex;
  justify-content: center;
  line-height: 3.6rem;
  padding: 0 1.5rem;
  font-size: 13px;
  font-weight: 600;
}