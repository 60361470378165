@import 'assets/styles/mixins';

.root {
  width: 20rem;
  background: $snow-white;
  transition: width .4s ease-in-out;
  border: 1px solid $base-grey-10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;

  bottom: 0;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 2;

  @include viewportMobile {
    width: 100%;
    background-color: $snow-white;
    position: unset;
    right: 0;
    z-index: 2;
    // width: 100%; // Occupy full width on mobile
    // left: 0; // Position at the left on mobile view
    // height: 5rem;

    &Hidden{
      width: 100% !important;
    }
  }

  .header{
    align-items: center;
    display: flex;
    padding: 0 2.5rem;
    flex: none;
    width: 100%;
    align-items: flex-start;
    justify-content: center;

    .headerLink{
      width: 100%;
      overflow: hidden;
      transition: all .4s ease-in-out;
      &Hidden {
        width: 2.4rem;
      }
    }

    @include viewportMobile {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      .icon{
        &Hidden{
          display: none;
        }
      }

      .headerLink{
        &Hidden {
          width: 100%;
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: auto;
    height: 100%;
    flex: 3;

    @include viewportMobile{
      width: 22rem;
      background-color: $snow-white;
      position: absolute;
      right: 0;
      z-index: 999999;
      transition: all .2s ease-in-out;

      &Hidden{
        // transition: all .8s ease-in-out;
        width: 0rem !important;
        // display: none;
      }
    }

  }

  .menu, .footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .icon{
      margin-top: 3rem;
      margin-bottom: 4rem;
    }

  }

  .logo {
    z-index: 99999999;
    width: 15rem;
  }

  .link {
    color: $ensure-dark;
    font-size: 1.6rem;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    margin: 5px 0;
    width: 75%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    transition: width .4s ease-in-out;
    border-radius: 6px;

    svg{
      opacity: 0.7;
      align-self: center;
    }

    &Active,
    &:hover {
      background: #F1EFFF;
      color: $ensure-light !important;
    }

    .title {
      align-self: center;
      opacity: 1;
      max-width: 100%;
      padding-left: 1rem;
      overflow: hidden;
      transition: all .4s ease-in-out;
      white-space: nowrap;
    }


    &Disabled {
      color: $base-grey-25;
      cursor: not-allowed;

      .title { color: $base-grey-25; }

      &:hover {
        background: none;
        color: $base-grey-25 !important;
      }
    }

    .img {
      color: $base-grey-50;
      margin: 0;
      height: 2.8rem;
      width: 2.8rem;
      border-radius: 50%;
      background: $snow-white;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #333333;
      font-size: 20px;
    }


    &Active,
    &:hover {
      background: #F1EFFF;
      .title {
        color: $ensure-dark !important;
      }
    }
  }

  .toggleButton {
    color: $base-grey-50;
    cursor: pointer;
    top: calc(50% - 1.8rem);
    position: absolute;
    right: -1.8rem;
    background-color: $base-grey-5;
    border-radius: 50%;
    border: 1px solid $base-grey-10;
    padding: .5rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    transition: transform .4s ease-in-out;
    z-index: 999999;

    @include viewportMobile{
      display: none;
    }
  }

  &Hidden {
    width: 10rem;

    .toggleButton {
      transform: rotateZ(180deg);
    }

    .link {
      width: 25%;

      .title {
        opacity: 0;
        max-width: 0;
        padding-left: 0;
      }
    }

    @include viewportMobile {
      &Hidden{
        width: 100%;
      }
    }

  }


  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: $ensure-gradient;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $ensure-gradient;
  }

}
