@import 'assets/styles/colors';

.root {
  width: 100%;
  height: 100%;

  .firstDiv {
    display: flex;
    justify-content: space-between;
    padding: 3rem 3rem 0 3rem;

    .one,
    .two,
    .three {
      height: 4rem;
      background-color: $base-grey-5;
      border: 1px solid $base-grey-10;
    }

    .one {
      width: 50%;
      border-radius: .6rem;
    }

    .two {
      margin-right: 1rem;
    }

    .two,
    .three {
      width: 4rem;
      border-radius: 50%;
    }
  }

  .secondDiv {
    padding: 0 3rem;

    .one {
      margin-top: 2.5rem;
      height: 4rem;
      background-color: $base-grey-5;
      border: 1px solid $base-grey-10;
      border-radius: .6rem;
    }
  }

  .fifthDiv {
    margin-top: 2rem;
    height: 45%;
    width: 100%;
    background-color: $base-grey-5;
    border-top: 1px solid $base-grey-10;
    border-bottom: 1px solid $base-grey-10;
    padding: 2.4rem;

    .one {
      height: 8rem;
      padding: 2rem;
      background-color: $white;
      border: 1px solid $base-grey-10;
      border-radius: .6rem;
      display: flex;
      justify-content: space-between;
      
      .ball {
        height: 4rem;
        background-color: $base-grey-5;
        border: 1px solid $base-grey-10;
        width: 4rem;
        border-radius: 50%;
      }

      .one,
      .two {
        height: 4rem;
        background-color: $base-grey-5;
        border: 1px solid $base-grey-10;
        border-radius: .6rem;
      }

      .one {
        width: 15rem;
      }

      .two {
        width: 25rem;
        margin-top: 0;
      }
    }

    .two {
      height: 5rem;
      margin-top: 2rem;
      background-color: $white;
      border: 1px solid $base-grey-10;
      border-radius: 2.4rem;
    }
  }

  .sixthDiv {
    display: flex;
    justify-content: space-between;
    padding: 3rem;

    .one,
    .two,
    .three {
      height: 4rem;
      background-color: $base-grey-5;
      border: 1px solid $base-grey-10;
      border-radius: 2.4rem;
    }

    .one,
    .two {
      width: 10rem;
    }

    .two {
      margin-right: 1rem;
    }

    .three {
      width: 12rem;
    }
  }
}