@import 'assets/styles/mixins';

.content {
  border-top: 1px solid $base-grey-10;
}

.subtitle {
  margin-top: 1rem !important;
}

.downloadButton{
  padding: 0rem 1.2rem !important;
}

.csv{
  min-width: 4rem;
  min-height: 4rem;
}

.icons{
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.stepIndicator {
  height: 3.5rem;
  width: 100%;
  display: flex;

  .step {
    @include flexCenter;
    width: 33%;
    height: 100%;
    text-align: center;
    color: $base-grey-50;
    font-weight: 600;
    font-size: 1.2rem;
    background-color: $base-grey-5;
    border-color: $base-grey-10;
    border-style: solid;

    &:nth-child(1) {
      border-width: 1px 1px 1px 1px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    
    &:nth-child(2) {
      border-width: 1px 1px 1px 0;
    }
    
    &:nth-child(3) {
      border-width: 1px 1px 1px 0;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    
    &Selected {
      background-color: $brand-color-lightest;
      color: $brand-color;
      border-color: $brand-color !important;
    }

    &Completed {
      background-color: $green;
      border-color: $green-150 !important;
      color: $white;
      cursor: pointer;
    }
  }
}