@import 'assets/styles/mixins';
.title {
    display: block;
    color: $secondary-text-color;
    font-weight: 500;
    font-size: 1.4rem;
    margin-bottom: .7em;
}
.urlInput{
    cursor: pointer;
}
.QRContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .QRCode{
        padding: 16px;
        border: 1px solid $base-grey-10;
        border-radius: 6px;
        background: $base-grey-5;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .canvasStyle{
        background: $white;
        border: 1px solid #E6E6E6;
        border-radius: 12px;
        // padding: 24px;
    }
    .description{
        align-self: flex-start;
        padding: 0px 16px;
        margin: 0px;
        font-weight: 400;
        font-size: 14px;
        ul{
            margin: 0px;
            padding-left: 30px;
        }
    }
    .downloadBtn{
        margin-top: 16px;
        font-weight: 600;
        font-size: 14px;
        padding: 16px;
        width: 100%;
        .downloadIcon{
            width: 18px;
            height: 18px;
            margin-right: 8px;
            color: $white;
        }
    }
}
