@import 'assets/styles/mixins';

.root {
  min-height: 65vh;
  margin-top: 1rem;
  max-width: 80rem !important;
  min-width: 80rem !important;
  flex: 1;
  overflow-y: scroll;

  @include viewportMobile {
   margin-top: 0rem !important;
   border: 0px !important;
   max-width: 40rem !important;
   min-width: 40rem !important;
  }

}