@import 'assets/styles/mixins';

.root {
  width: 90%;
  height: 5.6rem;
  margin-bottom: 1.6rem;
  @include text-body;

  p {
    display: flex;
    align-items: center;
  }

  .date {
    margin: 0 2rem;
  }

  .text {
    margin-left: 2rem;
    justify-content: center;
    span {
      color: $brand-color;
      font-weight: bold;
    }
  }

  &:last-of-type {
    &::before {
      height: 0;
    }
  }

  &::before {
    content: '';
    background-color: $brand-color-light;
    border-radius: 50%;
    width: .2rem;
    height: 7.3rem;
    top: 38%;
    position: absolute;
    left: -2rem;
    z-index: 2;
  }

  &::after {
    content: '';
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    top: 38%;
    margin: 0;
    left: -2.5rem;
    z-index: 1;
    position: absolute;
    background-color: $brand-color-light;
  }
}