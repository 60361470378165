@import 'assets/styles/mixins';

.header{
  margin-bottom: 2rem;
  .description{
    font-size: 1.4rem;
    font-weight: 400;
    color: #666666;
    margin: 5px 0;
  }
}

.accountAPI{
 max-width: 300px;
}
.logoWrapper{
  align-items: center;
  display: flex;
  height: 150px;
  justify-content: center;
  .logo{
    height: 80%;
    max-height: 80%;
    max-width: 60%;
  }
}

