@import 'assets/styles/mixins';

.root {
  min-width: initial !important;
  height: 100%;
  width: 100%;
  cursor: pointer;
  transition: transform .4s ease-in-out;

  &:hover:not(&Disabled) {
    transform: scale(1.05);
  }

  .content {
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%
  }

  .title {
    @include text-h3;
    margin: 0;
  }

  .badge {
    margin-left: 0;
    font-size: 1.4em;
    font-weight: 600;
    margin-bottom: .7em;
  }

  &Disabled {
    .title { color: $base-grey-25; }
  }

  @include viewportMobile {
    width: 100% !important;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .title {
      font-size: 1.5rem;
      text-align: center;
    }

    .content {
      padding: 1.2rem;
    }
  }
}


.blockBadge {
  justify-self: end;
  grid-column-start: 6;
  grid-column-end: 7;
  margin-right: 1rem;
  padding: 2px 12px 2px 12px;
  background-color: $base-grey-5;
  border: 1px solid $base-grey-10;
  border-radius: 100px;
  position: absolute;
  right: 2.4rem;
  align-self: flex-end;
  @include viewportMobile {
    right: 1rem;
  }
}

.active {
  &::after {
    content: '';
    position: absolute;
    top: -3px;
    z-index: 2;
    height: 2.4rem;
    width: 2.4rem;
    background: url(/assets/images/badgeactive.svg);
    background-size: 2.4rem;
    background-repeat: no-repeat;
  }
}

.inactive {
  &::after {
    content: '';
    position: absolute;
    top: -3px;
    z-index: 2;
    height: 2.4rem;
    width: 2.4rem;
    background: url(/assets//images/badgeinactive.svg);
    background-size: 2.4rem;
    background-repeat: no-repeat;
  }
}

