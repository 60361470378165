.notFound {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;

    h1 {
        margin-bottom: 0;
    }

    a {
        margin-top: 20px;
    }
}