@import 'assets/styles/mixins';

.root {
  margin-top: 1rem;
  max-height: 75vh;
  min-height: 50vh;
  padding: 2rem;
  max-width: 100rem;
  overflow-x: hidden;
  min-width: 80rem !important;
  max-width: 80rem !important;
  flex: 1;

  @include viewportMobile {
    border: 0px !important;
   }
  .content {
    justify-content: flex-start !important;
    align-items: flex-start !important; 
  }

  .blockInfo {
    margin: 0;
    

    h1 {
      margin: 1rem 0 8rem 0;
    }
    .top{
      display: flex;
      justify-content: space-between;
      .printer{
        cursor: pointer;
      }
    }
  }

  .shieldDiv {
    padding: 1.6rem;
  }

  .next {
    width: 100% !important;
  }
}

.button{
  width: 100%;
}

@include viewportMobile {
  .root {
    min-width: 90vw !important;
    min-height: 0;
    max-height: 80vh !important;
    padding: 2rem;
  }

  .title {
    margin-bottom: 2.4rem !important;
  }

  .button {
    width: 100%;
  }

  .next {
    width: 100%;
  }
}