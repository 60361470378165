@import 'assets/styles/mixins';

.root {
  height: 100%;
  width:100%;
}

.cardContent{
  border-bottom: 1px solid $base-grey-10
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
