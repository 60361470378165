@import 'assets/styles/mixins';

.root {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $snow-white !important;
  @include viewportMobile {
    min-height: 0vh !important;
  }
}

.content {
  transition: margin .4s ease-in-out;

  @include viewportMobile {
    margin-left: 0rem;
  }
}

.title {
  display: flex;
  align-items: center;
}

.subtitle {
  color: $base-grey-50;
  @include text-h5;
  margin: 1rem 0;
}
