@import "../../../../../../assets/styles/mixins";

.card {
  padding: 1rem 1rem 0rem 1rem;
  background-color: $base-grey-5;
  border: 1px solid $base-grey-10;
  border-radius: 8px;
  .row {
    display: flex;
    .select {
      flex: 8 1;
      .item {
        margin-bottom: 1rem;
        .button {
          background-color: white !important;
          border-radius: 6px 0px 0px 6px !important;
        }
      }
    }
    .remove {
      border-radius: 0 6px 6px 0;
      height: 4rem;
      width: 4rem;
    }
  }
}

.error {
  position: relative;
  bottom: 0;
  left: 0;
  color: $error-color;
  font-size: 1.1rem;
  text-transform: capitalize;

  &::before {
    content: "* ";
  }
}
