@import 'assets/styles/mixins';

.root {
  margin-bottom: 1.5rem;
  padding: 2.4rem !important;
}

.order {
  @include flexCenter;

  width: 34px;
  height: 34px;
  color: $base-grey-50;
  background-color: $base-grey-5;
  border: 1px solid $base-grey-10;
  border-radius: 50%;
  cursor: pointer;
  transition: all .4s ease-in-out;
  font-weight: bolder;
  grid-column: 1 / span 1;
  justify-self: start;
}

.blockSelector {
  grid-column: 2 / span 6;
  li {
    display: flex;
    align-items: center;
  }
}

.requiredSelector {
  grid-column: 8 / span 3;
}

.removeButton {
  grid-column: 11 / span 1;
  justify-self: end;
}