@import './variables';

.badge {
  padding: 3px 10px;
  border-radius: 12px;
  font-size: 1.4rem;
  max-height: 2rem;

  &--success, &--completed {
    background-color: $success-color-light !important;
    color: $success-color !important;
  }

  &--default {
    background-color: $base-grey-10 !important;
    color: $base-grey-50 !important;
  }
  &--pending {
    background-color: $pending-color-light-background !important;
    color: $pending-color-background;
    font-weight: 600;
  }

  &--info {
    background-color: $blue !important;
    color: $white !important;
  }

  &--in_progress {
    background-color: $in_progress-color-light-background  !important;
    color: $in_progress-color-text !important;
  }


  &--overdue {
    background-color: $error-color-light  !important;
    color: $error-color  !important;
  }

  &--status {
    &-pending {
      background-color: $pending-color-light-background !important;
      color: $pending-color-background;
      font-weight: 600;
    }

    &-in_progress {
      background-color: $in_progress-color-background !important;
      color: $text-color;
      font-weight: 600;
    }

    &-completed {
      background-color: $completed-color-background !important;
      color: $text-color;
      font-weight: 600;
    }

    &-light {
      &-pending {
        background-color: $pending-color-light-background !important;
        color: $pending-color-background;
        font-weight: 600;
      }
  
      &-in_progress {
        background-color: $in_progress-color-light-background !important;
        color: $in_progress-color-background;
        font-weight: 600;
      }
  
      &-completed {
        background-color: $completed-color-light-background !important;
        color: $completed-color-background;
        font-weight: 600;
      }
    }
  }
}