.button {
  margin-bottom: 0.5rem;
}

.useCamera {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
