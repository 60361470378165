@import 'assets/styles/mixins';

.root {
  position: relative;
  width: 100%;
  cursor: pointer;

  input { display: none; }

  &Disabled {

  }
  .error {
    position: relative;
    bottom: 0;
    left: 0;
    color: $error-color;
    font-size: 1.1rem;
    text-transform: capitalize;

    &::before {
      content: '* ';
    }
  }
}

.menu {
  position: absolute;
  display: none;
  background-color: $white;
  min-width: 100%;
  max-width: 50rem;
  max-height: 30rem;
  z-index: 99;
  padding: 0;
  box-shadow: 0px 6px 10px 0px #00000033;
  border-radius: 6px;
  overflow: auto;

  &Visible { display: block }

  .item {
    @include text-body;
    list-style: none;
    padding-inline-start: 0;
    padding: 1.2rem;

    &:hover { background-color: $base-grey-10; }
  }

  .active{
    background-color: $base-grey-10;
  }

  .disabled {
    display: none;
  }

  .itemDescription {
    white-space: nowrap;
    color: $base-grey-50;
    font-size: 1rem;
    font-size: 1.3rem;
    margin: 0.5rem 0;
  }
}

.button {
  @include input;
  line-height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow: hidden;
  span {
    height: 100%;
  }

  &::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid $base-grey;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  &.disabled {
    span { color: $base-grey-35; }

    &::after {
      border-top-color: $base-grey-35;
    }
  }
}

.placeholder {
  color: $base-grey-50;
}

.red{
  border-color: $red;
}
