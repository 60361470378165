@import 'assets/styles/mixins';

.root {
  position: relative;

  .icon {
    position: absolute;
    color: $secondary-text-color;
    top: 50%;
    margin-top: calc(-1.8rem / 2);
    margin-left: calc(1.5rem - 1.8rem / 2);
    height: 1.8rem;
    width: 1.8rem;
  }

  .input {
    @include input;

    padding-left: 3rem;
  }
}