@import "~bootstrap/scss/bootstrap-utilities";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";

@import './badge';
@import './fonts';
@import './utils';
@import './tooltip';

html {
  font-family: inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 10px;
}

p, h1, h2, h3, h4, h5, label, span, input, li  { color: $base-grey; }

hr {
  border: none;
  border-top: 1px solid $base-grey-10;
  width: 100%;
}

body {
  margin: 0;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5 {
  font-family: urw-gothic, sans-serif;
}

h1 { font-size: 3.2rem; }

input { font-family: inherit; }

div { box-sizing: border-box; }