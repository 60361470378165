@import 'assets/styles/mixins';

.checkboxBar{
  width: 100%;
  background-color: $white;
  padding: 0rem 3rem;

  .bar{
    height: 4.5rem;
    background: $brand-color;
    border: 1px solid $brand-color ;
    border-radius: 8px;

    .content{
      margin-left: 1rem;
      padding-left: 2rem;
      display: flex;
      justify-content: space-between;
      .archiveJourneys{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.5rem;
        margin-top: 0.1rem;
        .button{
          background-color: $brand-color-dark;
          margin-right: 1rem;
          padding: 0rem 2rem !important;
        }
      }
      .text{
        font-family: 'Inter';
        font-style: normal;
        font-size: 14px;
        font-weight: 400;
        color:$white;
        padding-top: 1.2rem;
      }
    }
  }
  .arrowDown {
    width: 0; 
    height: 0; 
    margin-left: 0.9rem;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid $brand-color;
  }
}

.tableHeaders {
  width: 100%;
  display: grid;
  grid-template-columns: .1fr .7fr repeat(4, 1fr);
  align-items: center;
  font-size: 1.2rem;
  background-color: $white;
  padding: .5rem 3.5rem;
  border-bottom: 1px solid $base-grey-10;

  span {
    @include text-body;
  }
}

.tableRows {
  padding: 3rem;
}

.select {
  width: 16rem;
}

.reactSelect{
  line-height: 3.1rem;
}

.filter {
  cursor: pointer;
}

.checkbox{
  height: 1.8rem !important;
  width: 1.8rem !important;
}

.statusParent {
  grid-column-start: 2;
  margin-left: 1.3rem;
}

.message {
  height: 60vh;
}

.addBtn {
  width: 13rem;
  height: 4.5rem;
}

.centerBtn {
  height: 4.5rem;
}

.centerTitle {
  font-size: 2rem;
}