@import 'assets/styles/colors';

.fieldRow {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 3rem;
}

.options { 
  margin-block-start: 0;
  padding-inline-start: 2em;

  .option {
    color: $base-grey-25;

    &Selected {
      color: $base-grey;
    }
  }
}
