@import 'assets/styles/colors';

.root {
  width: 100%;

  .firstDiv {
    display: flex;
    justify-content: space-between;
    padding: 3rem 3rem 0 3rem;

    .one,
    .two,
    .three {
      height: 4rem;
      background-color: $base-grey-5;
      border: 1px solid $base-grey-10;
    }

    .one {
      width: 50%;
      border-radius: 2.4rem;
    }

    .two {
      margin-right: 1rem;
    }

    .two,
    .three {
      width: 4rem;
      border-radius: 50%;
    }
  }

  .secondDiv {
    padding: 0 3rem;

    .one {
      margin-top: 2.5rem;
      height: 2rem;
      width: 35%;
      background-color: $base-grey-5;
      border: 1px solid $base-grey-10;
      border-radius: 2.4rem;
    }
  }

  .thirdDiv {
    padding: 0 3rem;

    .one {
      margin-top: 4.5rem;
      height: 2rem;
      background-color: $base-grey-5;
      border: 1px solid $base-grey-10;
      border-radius: 2.4rem;
    }
  }

  .fourthDiv {
    padding: 0 3rem;

    .one {
      margin-top: 2rem;
      height: 6.6rem;
      background-color: $base-grey-5;
      border: 1px solid $base-grey-10;
      border-radius: 2.4rem;
    }
  }

  .fifthDiv {
    margin-top: 2rem;
    height: 45%;
    width: 100%;
    background-color: $base-grey-5;
    border-top: 1px solid $base-grey-10;
    border-bottom: 1px solid $base-grey-10;
    padding: 2.4rem;

    .one {
      height: 100%;
      background-color: $white;
      border: 1px solid $base-grey-10;
      border-radius: 2.4rem;
    }
  }

  .sixthDiv {
    display: flex;
    justify-content: space-between;
    padding: 3rem 3rem 0 3rem;

    .one,
    .two,
    .three {
      height: 4rem;
      background-color: $base-grey-5;
      border: 1px solid $base-grey-10;
      border-radius: 2.4rem;
    }

    .one,
    .two {
      width: 10rem;
    }

    .two {
      margin-right: 1rem;
    }

    .three {
      width: 12rem;
    }
  }
}