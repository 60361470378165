
@import 'assets/styles/mixins';

.root{
    margin-top: 3rem;
    .title{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      .badge {
        margin-left: 0;
        font-size: 1rem;
        font-weight: 600;
      }
    }
    
    .block {
        margin-top: 2rem;
        width: 90%;
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        row-gap: 2em;
        column-gap: 2em;
        place-items: center;
        margin-bottom: 32px;

        .card{
            margin-left: 4rem;
        }
      
        @include viewportMobile {
          grid-template-columns: 100%;
        }
      }

}

.subtitle{
  font-size: 16px;
  font-weight: 400;
}


.body{
  // background-color: red;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: $branding-background-color;
  margin-bottom: 3rem;
  margin-top: 3rem;
  margin-left: 4rem;
  .card{
    .text{
      font-size: 14px;
      text-align: center;
    }
    span{
      font-weight: 500;
    }
    background-color: $white;
    padding: 2rem;
    width: 90%;
  }
}
