@import 'assets/styles/mixins';

.mainContainer {
  @include fullScreen;
  @include flexCenter;
  flex-direction: column;

  .contentContainer {
    padding: 60px 60px 40px;
    gap: 32px;
    position: relative;

    .primaryText {
      position: absolute;
      width: 100%;
      left: 14%;
      top: 0px;
      margin-top: 5rem;

      &::before {
        left: 37.5%;
        right: 0;
        top: 55%;
        background-size: 34%;
      }

      @include viewportMobile {
        left: 10%;
        @include text-h3;
        &::before {
          left: 43%;
        }
      }
    }

    .primaryAdjusted {
      left: 12%;

      &::before {
        left: 0%;
        background-size: 32%;
      }

      @include viewportMobile {
        &::before {
          left: 0%;
          top: 60%;
          background-size: 34%;
        }
      }
    }

    .secondaryText {
      width: 360px;
      height: 40px;
      margin: 5rem 0 0 0;
      font-family: 'Inter';
      @include text-body;
      @include viewportMobile {
        width: 280px;
        text-align: center;
      }
    }

    .secondaryAdjusted {
      text-align: center;
    }
  }
}