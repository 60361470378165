@import 'assets/styles/mixins';

.root {
  padding: 2.4rem !important;
  .header{
    height: auto !important;
  }
}

.textArea{
  margin-top: 1.2rem;
}

.textareaExpanded {
  textarea {
    min-height: 80px !important;
  }
}

.order {
  @include flexCenter;

  width: 34px;
  height: 34px;
  color: $base-grey-50;
  background-color: $base-grey-5;
  border: 1px solid $base-grey-10;
  border-radius: 50%;
  cursor: pointer;
  transition: all .4s ease-in-out;
  font-weight: bolder;
  grid-column: 1 / span 1;
  justify-self: start;
}

.typeSelector {
  grid-column: 2 / span 4;
  ul {
    width: 40rem !important;
  }
}

.input {
  width: 100%;
  grid-column: 6 / span 5;
}

.removeButton {
  grid-column: 11 / span 1;
  justify-self: end;
}

.token{
  cursor: copy;
  font-size: 13px;
  margin-top: 3rem;
}