@import 'assets/styles/mixins';

.root {
  @include input;
  height: initial;
  padding: 1.2rem;
  resize: none;

  &WithError {
    padding-bottom: 1.5rem;
    border: 1px solid #EB5757;

    &:hover {
      border: 1px solid #EB5757 !important;
    }

    &:focus {
      border: 1px solid #EB5757 !important;
    }
  }
}

.textareaContainer {
  position: relative;
}

.error {
  position: absolute;
  bottom: -12px;
  left: 0;
  color: $error-color;
  font-size: 1.1rem;
  text-transform: capitalize;

  &::before {
    content: '* ';
  }
}

.lock {
  position: absolute;
  color: $secondary-text-color;
  top: 4.5rem;
  right: 2rem;
  margin-top: calc(-1.8rem / 2);
  margin-left: calc(1rem - 1.8rem / 2);
  height: 1.8rem;
  width: 2.8rem;
  color: black;
}

.disabled {
  opacity: 0.8;
}