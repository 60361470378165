/////////////////////////////////// MIXINS /////////////////////////////////////

@import './variables';


///////// Templates /////////
@mixin fullScreen {
  background-image: $branding-gradient;
  min-width: 100vw;
  min-height: 100vh;
}


///////// Position /////////
@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}



///////// Responsive breakpoints /////////
@mixin viewportMobile {
  @media (max-width: 576px) { @content; }
}
@mixin viewportSmall {
  @media (min-width: 576px) { @content; }
}
@mixin viewportMedium {
  @media (min-width: 768px) { @content; }
}
@mixin viewportLarge {
  @media (min-width: 992px) { @content; }
}
@mixin viewportXLarge {
  @media (min-width: 1200px) { @content; }
}


///////// Buttons/Links Pseudo States /////////
@mixin pseudo-states {
  &:not(:disabled):active,
  &:not(:disabled):focus,
  &:not(:disabled):hover,
  &:not(:invalid):active,
  &:not(:invalid):focus,
  &:not(:invalid):hover,
  &:not(.invalid):active,
  &:not(.invalid):focus,
  &:not(.invalid):hover { @content; }
}


///////// Card /////////
@mixin card {
  position: relative;
  background-color: $white;
  border-radius: 10px;
  min-width: 45rem;

  &-with-shadow { box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2); }

  &-with-border { border: 1px solid $base-grey-10; }

  &_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }

  &_footer {
    @include flexCenter;
    width: 100%;
    bottom: 0;
    border-top: 1px solid $base-grey-10;
    padding: 2rem;
  }

  &-scrollable { overflow: scroll; }
  
  @include viewportMobile {
    min-width: 32rem;
    width: 320px;
  }
}


///////// Link /////////
@mixin link {
  outline: none;
  display: inline-block;
  color: $brand-color;
  font-weight: 500;
  font-size: inherit;
  text-decoration: none;
  cursor: pointer;
  transition: color .4s ease-in-out;

  &:hover {
    color: $brand-color-dark;
  }
}

///////// Forms /////////
@mixin input {
  @include text-body;
  font-family: inter, sans-serif;
  background-color: $base-grey-5;
  border: 1px solid $base-grey-10;
  border-radius: 6px;
  height: 4rem;
  padding: 0 1.2rem;
  transition: border-color .4s ease-in-out;
  outline: none;
  box-sizing: border-box;
  width: 100%;

  @include pseudo-states() {
    border-color: $brand-color;
  }

  &:invalid,
  &.invalid {
    border-color: $error-color;
    @include pseudo-states() {
      border-color: $error-color;
    }
  }

  &.disabled {
    color: $base-grey-35;
    cursor: not-allowed;

    @include pseudo-states() { border-color: $base-grey-10; }
  }
}


///////// Button /////////
@mixin button {
  @include flexCenter;
  @include text-body;
  font-family: inter, sans-serif;
  height: 4rem;
  border: none;
  border-radius: 3rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color .4s ease-in-out;
  padding: 0 3rem;
  background-color: $brand-color;
  color: $white;
  text-decoration: none;

  @include pseudo-states() {
    background-color: $brand-color-dark;
  }
}


///////// Text /////////

@mixin text-h1 {
  font-size: 4rem;
  font-weight: 600;
}

@mixin text-h2 {
  font-size: 3.2rem;
  font-weight: 600;
}

@mixin text-h3 {
  font-size: 2.4rem;
  font-weight: 600;
}

@mixin text-h4 {
  font-family: inter, sans-serif;
  font-size: 1.8rem;
}

@mixin text-h5 {
  font-family: inter, sans-serif;
  font-size: 1.6rem;
}

@mixin text-h6 {
  font-family: inter, sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
}

@mixin text-body {
  font-size: 1.4rem;
  font-weight: 400;
}

@mixin text-small {
  font-size: 1.2rem;
}

@mixin text-tiny {
  font-size: 1.1rem;
}