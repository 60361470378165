@import 'assets/styles/mixins';

.root {
  @include flexCenter;

  display: inline-flex;
  border: 1px solid $base-grey;
  color: $base-grey;
  border-radius: 50%;
  min-width: 1.6rem !important;
  height: 1.6rem !important;
  font-size: 1.1rem;
  line-height: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
}