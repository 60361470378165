@import 'assets/styles/colors';

input[type=checkbox] {
  -webkit-appearance: none;
  appearance: none;
  width: 2rem;
  height: 2rem;
}

.checkbox {
  width: 2rem;
  height: 2rem;
  border: 1px solid $base-grey-10;
  border-radius: 3px;
  transition: .3s all;
  position: relative;

  &:hover {
    background-color: $base-grey-10;
  }
}

.checkboxActive {
  width: 2rem;
  height: 2rem;
  border: 1px solid $brand-color;
  background-color: $brand-color;
  border-radius: 3px;
  transition: .3s all;
  position: relative;
  
  &:hover {
    background-color: $brand-color-dark;
  }

  &::before {
    position: absolute;
    left: 0;
    top: 40%;
    height: 45%;
    width: 4px;
    background-color: #ffffff;
    content: "";
    transform: translateX(7px) rotate(-45deg);
    transform-origin: left bottom;
  }

  &::after {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    width: 70%;
    background-color: #ffffff;
    content: "";
    transform: translateY(-3px) translateX(8px) rotate(-45deg);
    transform-origin: left bottom;
  }
}