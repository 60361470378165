@import 'assets/styles/mixins';

.root {
  @include flexCenter;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 60%;
  max-width: 50rem !important;
  padding: 32px;
  margin: auto;

  @include viewportMobile {
    max-width: 100%;
    justify-content: flex-start;
    padding: 32px;

  }
}

.content{
  padding: 0px !important;
  width: 100%;
    .header{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
        .logo {
          flex: 1;
          width: 73px;
          height: 87px;
          object-fit: contain;
        }
        .title{
          margin: 24px 0px 8px 0px;
          font-weight: 600;
          font-size: 24px;
          text-align: center
        }
        .subTitle {
          margin: 0px 0px 24px 0px;
          text-align: center;
          font-weight: 400;
          font-size: 14px;
        }
    }
}

.input{
  font-weight: 600;
  font-size: 14px;
  color: $base-grey;
  margin-bottom: 24px;
}
.inputLabel{
  font-weight: 600 !important;
  font-size: 14px !important;
  color: $base-grey;
}
.submitBtn{
  width: 100%;
  height: 2.5em;
  border-radius: 10px;
}
